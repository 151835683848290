import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Auth } from '../../../service/auth/WSimAuth';
import { ErrorMessage } from '../../../shared/error-message';
import { LoginStep } from '../sign-in.component';

@Component({
    selector: 'app-inital-password-change',
    templateUrl: './initial-password-change.component.html',
    styleUrls: ['./initial-password-change.component.scss']
})
export class InitialPasswordChangeComponent {

    @Output()
    changeStep = new EventEmitter<LoginStep>();

    @Output()
    showError = new EventEmitter<ErrorMessage>();

    form: FormGroup;
    resetDisabled = false;
    error: ErrorMessage | undefined;

    requestNewCode = false;

    constructor(private formBuilder: FormBuilder) {
        this.form = this.formBuilder.group({
            password: [null, Validators.required],
            resetCode: [null, Validators.required],
            emailAddress: [null, [Validators.required, Validators.email]]
        });
        this.form.controls['emailAddress'].setValue(Auth.getCurrentUser()?.getUsername());
    }

    public doSubmit() {
        this.resetDisabled = true;
        const formValue = this.form.value;
        const password = formValue['password'];
        const resetCode = formValue['resetCode'];

        Auth.getCurrentUser()?.confirmPassword(resetCode, password, {
            onSuccess: () => {
                this.resetDisabled = true;
                this.changeStep.emit(LoginStep.Login);
            }, onFailure: err => {
                this.resetDisabled = true;
                this.showError.emit(new ErrorMessage(err.name, err.message));
            }
        });
    }

    requestCode() {
        this.requestNewCode = true;
        Auth.getCurrentUser()?.forgotPassword({
            onSuccess: () => {
                this.requestNewCode = false;
            }, onFailure: (err: Error) => {
                this.requestNewCode = false;
                this.showError.emit(new ErrorMessage(err.name, err.message));
            }
        });
    }
}
