import { Component, inject, Input, ViewChild } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { DxDataGridComponent, DxPopoverComponent } from 'devextreme-angular';

interface DetailModel {
    id: number;
    text: string;
    value: string[];
    checked: boolean;
}

@Component({
    selector: 'app-details-header',
    templateUrl: './details-header.component.html',
    styleUrl: './details-header.component.scss'
})
export class DetailsHeaderComponent {

    protected i18next = inject(I18NextPipe);

    protected filterActive = false;

    @Input({ required: true })
    public dataGrid!: DxDataGridComponent;

    @ViewChild('popover', { static: false })
    protected popover?: DxPopoverComponent;

    // I don't like that - because we have already defined the wsim blue light color - but it isn't simply accessible in typescript
    private filterSelectedColor = '#edf4fa'; // wsim-blue-light
    private defaultColor = '#e9e9ef'; // wsim-gray-2

    protected filterModel: DetailModel[] = [
        {
            id: 1,
            text: this.i18next.transform('eventFilter.consumption'),
            value: ['consumption'],
            checked: true
        }, {
            id: 2,
            text: this.i18next.transform('eventFilter.presence'),
            value: ['presence'],
            checked: true
        }, {
            id: 3,
            text: this.i18next.transform('eventFilter.usageNotification'),
            value: ['portalInternalUsageNotification'],
            checked: true
        }, {
            id: 4,
            text: this.i18next.transform('eventFilter.lifeCycle'),
            value: ['lifeCycle', 'portalInternalLifeCycle'],
            checked: true
        }];


    protected openFilter($event: MouseEvent) {
        $event.stopPropagation();
        this.popover?.instance.toggle(true);
    }

    public deleteFilter() {
        this.filterModel.forEach((item) => {
            item.checked = true;
        });
        // reset filter by setting empty array
        this.removeDataGridFilter();
        this.filterActive = false;
        this.getColumnHeaderHtmlElement().style.backgroundColor = this.defaultColor;
        this.popover?.instance.toggle(false);
    }

    public optionChanged(event: Event) {
        const eventTypes: string[] = [];
        let countChecked = 0;
        this.filterModel.forEach((item: DetailModel) => {
            if (item.checked) {
                eventTypes.push(...item.value);
                countChecked ++;
            }
        });
        console.log('selected: ', eventTypes);
        // when at least one is checked and not all, we need to set the filter. if not, we can remove the filter (show all or none)
        if (countChecked > 0 && countChecked != this.filterModel.length) {
            let currentFilter = this.dataGrid.instance.getCombinedFilter();
            const newFilterValue = ['eventTypes', eventTypes];
            if (currentFilter) {
                if (currentFilter.indexOf('eventTypes') > -1) {
                    currentFilter.splice(currentFilter.indexOf('eventTypes'), 2);
                }
                currentFilter.push(...newFilterValue);
            } else {
                currentFilter = newFilterValue;
            }
            this.dataGrid.instance.filter(currentFilter);
            this.filterActive = true;
            this.getColumnHeaderHtmlElement().style.backgroundColor = this.filterSelectedColor;
        } else {
            if (event && event.target) {
                (event.target as HTMLInputElement).checked = true;
            }
            this.deleteFilter();
        }
    }

    protected removeDataGridFilter() {
        const currentFilter = this.dataGrid.instance.getCombinedFilter();
        if (currentFilter && currentFilter.indexOf('eventTypes') > -1) {
            currentFilter.splice(currentFilter.indexOf('eventTypes'), 2);
        }
        this.dataGrid.instance.filter(currentFilter);
    }

    private getColumnHeaderHtmlElement(): HTMLElement {
        return <HTMLElement>document.querySelectorAll('[id*="dx-col"]')[1];
    }

}
