import { Component } from '@angular/core';
import i18next from 'i18next';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'

})
export class SettingsComponent {

    /**
     * Here would be filled with the options for future settings like security, notifications, etc.
     * At the moment is only notifications implemented
     * */

    protected readonly i18next = i18next;

    constructor() {
    }


}
