import { NgOptimizedImage } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { AgGridModule } from 'ag-grid-angular';
import { I18NEXT_SERVICE, I18NextModule, ITranslationService } from 'angular-i18next';
import {
    DxDataGridModule,
    DxDateBoxModule,
    DxPopoverModule,
    DxRadioGroupModule
} from 'devextreme-angular';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { ClipboardModule } from 'ngx-clipboard';
import { DndModule } from 'ngx-drag-drop';
import { devExtremeLicenseKey } from '../devextreme-license';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './auth/forgot-password/set-password/set-password.component';
import { InitialPasswordChangeComponent } from './auth/sign-in/initial-password-change/initial-password-change.component';
import { LoginComponent } from './auth/sign-in/login/login.component';
import { NewPassComponent } from './auth/sign-in/new-pass/new-pass.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { ModalBulkChangeSimCustomField1Component } from './dialogs/modal-bulk-change-sim-custom-field1/modal-bulk-change-sim-custom-field1.component';
import { ModalBulkChangeSimDataLimitComponent } from './dialogs/modal-bulk-change-sim-data-limit/modal-bulk-change-sim-data-limit.component';
import { ModalBulkChangeSimStatusComponent } from './dialogs/modal-bulk-change-sim-status/modal-bulk-change-sim-status.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ByteFormatPipe } from './pipes/byte-format.pipe';
import { CountryCodeToFlagPipe } from './pipes/country-code-to-flag.pipe';
import { CountryCodeToNamePipe } from './pipes/country-code-to-name.pipe';
import { MsisdnFormatPipe } from './pipes/msisdn-format.pipe';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { CustomFieldComponent } from './shared/ag-cell-editor/custom-field/custom-field.component';
import { DataVolumeEditComponent } from './shared/ag-cell-editor/data-volume-edit/data-volume-edit.component';
import { SendSmsComponent } from './shared/ag-cell-editor/send-sms/send-sms.component';
import { DataVolumeLimitComponent } from './shared/ag-cell-renderer/data-volume-limit/data-volume-limit.component';
import { InSessionComponent } from './shared/ag-cell-renderer/in-session/in-session.component';
import { MsisdnComponent } from './shared/ag-cell-renderer/msisdn/msisdn.component';
import { SimStatusComponent } from './shared/ag-cell-renderer/sim-status/sim-status.component';
import { SmsAmountComponent } from './shared/ag-cell-renderer/sms-amount/sms-amount.component';
import { TextContainerWithCopyToClipboardComponent } from './shared/ag-cell-renderer/text-container-with-copy-to-clipboard/text-container-with-copy-to-clipboard.component';
import { StatusFilterComponent } from './shared/ag-filter/status-filter/status-filter.component';
import { CheckboxClearSelectorComponent } from './shared/ag-headers/checkbox-clear-selector/checkbox-clear-selector.component';
import { CopyToClipboardComponent } from './shared/copy-to-clipboard/copy-to-clipboard.component';
import { PasswordStrengthComponent } from './shared/password-strength/password-strength.component';
import { ToastsContainerComponent } from './shared/toasts-container/toasts-container.component';
import { BulkActionsComponent } from './sim-list/bulk-actions/bulk-actions.component';
import { TimestampHeaderComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/custom-cell-header/timestamp-header/timestamp-header.component';
import { DetailConsumptionComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-consumption/detail-consumption.component';
import { DetailDefaultComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-default/detail-default.component';
import { DetailLifecycleComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-lifecycle/detail-lifecycle.component';
import { DetailPresenceComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-presence/detail-presence.component';
import { DetailUsageNotificationComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/detail-usage-notification/detail-usage-notification.component';
import { SimDetailEventTableDevexComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/sim-detail-event-table-devex.component';
import { SimDetailEventTableComponent } from './sim-list/sim-detail/sim-detail-event-table/sim-detail-event-table.component';
import { SimDetailStatusComponent } from './sim-list/sim-detail/sim-detail-status/sim-detail-status.component';
import { SimDetailComponent } from './sim-list/sim-detail/sim-detail.component';
import { SimListComponent } from './sim-list/sim-list.component';
import { TableConfigComponent } from './sim-list/table-config/table-config.component';
import { TableQuicksearchComponent } from './sim-list/table-quicksearch/table-quicksearch.component';
import { StatisticComponent } from './statistic/statistic.component';
import { DetailsHeaderComponent } from './sim-list/sim-detail/sim-detail-event-table-devex/custom-cell-header/details-header/details-header.component';

export function appInit(i18next: ITranslationService, http: HttpClient) {
    return () => {
        const promise = i18next
            .use(HttpApi)
            .use(I18nextBrowserLanguageDetector)
            .init({
                supportedLngs: environment.supportedLanguages,
                fallbackLng: 'en',
                debug: !environment.production,
                returnEmptyString: false,
                ns: [
                    'default',
                    'formats',
                    'grid'
                ],
                defaultNS: 'default',
                backend: {
                    loadPath: 'assets/locales/{{lng}}.{{ns}}.json'
                },
                detection: {
                    lookupCookie: 'lng',
                    lookupLocalStorage: 'lng',
                    lookupSessionStorage: 'lng',
                    caches: ['localStorage']
                }
            });
        promise.then(() => {
            // Add custom formatter
            i18next.services.formatter?.add('cap', (value) => {
                return value.charAt(0).toUpperCase() + value.slice(1);
            });
            i18next.events.languageChanged.subscribe((lng) => {
                // setting locale for DevExtreme
                if (lng) {
                    locale(lng);
                    http.get(`assets/devex/${lng}.json`, { responseType: 'json' }).subscribe((data) => {
                        loadMessages(data);
                    });
                }
            });
        });
        return promise;
    };
}

export function localeIdFactory(i18next: ITranslationService) {
    return i18next.language;
}

export const I18N_PROVIDERS = [
    {
        provide: APP_INITIALIZER,
        useFactory: appInit,
        deps: [I18NEXT_SERVICE, HttpClient],
        multi: true
    },
    {
        provide: LOCALE_ID,
        deps: [I18NEXT_SERVICE],
        useFactory: localeIdFactory
    }];

declare global {
    interface Window {
        Cypress: boolean;
    }
}

// DevExtreme Config
config({ licenseKey: devExtremeLicenseKey });

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        SimListComponent,
        NewPassComponent,
        AutofocusDirective,
        LoginComponent,
        InitialPasswordChangeComponent,
        StatusFilterComponent,
        SimStatusComponent,
        MsisdnComponent,
        MsisdnFormatPipe,
        InSessionComponent,
        DataVolumeLimitComponent,
        ByteFormatPipe,
        CustomFieldComponent,
        SendSmsComponent,
        SmsAmountComponent,
        DataVolumeEditComponent,
        ChangePasswordComponent,
        ModalBulkChangeSimStatusComponent,
        ToastsContainerComponent,
        ModalBulkChangeSimCustomField1Component,
        ModalBulkChangeSimDataLimitComponent,
        SettingsComponent,
        NotificationsComponent,
        TableConfigComponent,
        CheckboxClearSelectorComponent,
        TextContainerWithCopyToClipboardComponent,
        StatisticComponent,
        BulkActionsComponent,
        TableQuicksearchComponent,
        SimDetailComponent,
        SimDetailEventTableComponent,
        PageNotFoundComponent,
        ForgotPasswordComponent,
        SetPasswordComponent,
        PasswordStrengthComponent,
        SimDetailEventTableDevexComponent,
        SimDetailStatusComponent,
        CopyToClipboardComponent,
        DetailPresenceComponent,
        DetailLifecycleComponent,
        DetailConsumptionComponent,
        DetailDefaultComponent,
        DetailUsageNotificationComponent,
        TimestampHeaderComponent,
        DetailsHeaderComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        I18NextModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        AgGridModule,
        ClipboardModule,
        DigitOnlyModule,
        NgbModule,
        DndModule,
        DxDataGridModule,
        CountryCodeToFlagPipe,
        CountryCodeToNamePipe,
        DxRadioGroupModule,
        DxDateBoxModule,
        DxPopoverModule
    ],
    providers: [
        I18N_PROVIDERS,
        ByteFormatPipe,
        MsisdnFormatPipe,
        provideHttpClient()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
