import { Component, HostBinding } from '@angular/core';
import { ToastService } from '../../service/toast.service';


@Component({
    selector: 'app-toasts',
    templateUrl: './toasts-container.component.html'
})
export class ToastsContainerComponent {

    @HostBinding('class')
    public hostClass = 'toast-container position-fixed top-0 end-0 p-3'

    @HostBinding('style')
    public hostStyle = 'z-index: 1200'

    constructor(public toastService: ToastService) {
    }

}
