import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';
import { StatusConstants } from '../../shared/constants';

@Component({
    selector: 'app-modal-bulk-change-sim-data-limit',
    templateUrl: './modal-bulk-change-sim-data-limit.component.html',
    styleUrl: './modal-bulk-change-sim-data-limit.component.scss'
})
export class ModalBulkChangeSimDataLimitComponent {

    // for input from another component
    @Input()
    public selectedSimCards?: Sim[];

    public inProgress = false;

    public saveEnabled = false;

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;

    private modal?: NgbModalRef;
    public unsavedLimit?: string;

    public currentDataUnit = 'MB';
    public availableDataUnits = [ 'MB', 'GB'];
    public disabled = 0;
    public enabled = 0;
    protected enabledList: Sim[] = [];
    protected disabledList: Sim[] = [];

    constructor(private graphQlService: GraphqlService, private toastService: ToastService,
                private i18nextPipe: I18NextPipe, private modalService: NgbModal) {
    }

    public open() {
        this.evaluateTargets();
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'bulkChangeSimDataLimitLabel' });
        return this.modal;
    }

    public close() {
        this.modal?.close();
    }

    protected onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            this.commitChange();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    protected commitChange() {
        this.inProgress = true;
        let limitInBytes = this.getLimitInBytes();
        if (!limitInBytes) {
            // Otherwise the GraphQL does nothing...
            limitInBytes = 0;
        }

        // collect the promises and wait with closing the dialog until we sent out all ...
        const successfulArray: string[] = [];
        if (this.selectedSimCards) {
            const promises: Promise<FetchResult<UpdateSimMutation>>[] = this.selectedSimCards.map((simCard) => this.graphQlService.updateSim(simCard.iccid, undefined, limitInBytes, undefined));

            // very important: promises are resolved in order. so we can access the original array for icc id mutation was called for:
            // https://stackoverflow.com/a/67274260
            Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]) => {
                results.forEach((promiseResult, index) => {
                    if (promiseResult.status === 'fulfilled') {
                        if (promiseResult.value.data?.updateSim.iccid) {
                            successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                        }
                    } else {
                        if (this.selectedSimCards) {
                            const iccId = this.selectedSimCards[index].iccid;
                            const text = this.i18nextPipe.transform('bulkDataLimitChange.errorChange', {
                                iccId: iccId,
                                error: promiseResult.reason?.message
                            });
                            this.toastService.show(ToastMessageBuilder.error().text(text).build());
                        }
                    }
                });
                this.inProgress = false;
                if (successfulArray.length > 0) {
                    const text = this.i18nextPipe.transform('bulkDataLimitChange.successChange', { count: successfulArray.length });
                    this.toastService.show(ToastMessageBuilder.success().text(text).build());
                }
                this.modal?.close();
            });
        }
    }

    protected evaluateTargets() {
        const limitBytes: number | undefined = this.getLimitInBytes();
        console.log('Eval: New limit', limitBytes);
        let enabled = 0;
        let disabled = 0;
        const disabledList: Sim[] = [];
        const enabledList: Sim[] = [];

        this.selectedSimCards?.forEach((sim) => {
            console.log('Eval:', sim.monthly_data_limit, sim.monthly_data_volume, sim.statusid);

            if (this.isLimitIncreased(limitBytes, sim)) {
                console.log('Increased!');
                if (this.isSimGetsActivated(limitBytes, sim)) {
                    console.log('Enabled');
                    enabled++;
                    enabledList.push(sim);
                }
            } else if (this.isLimitReduced(limitBytes, sim)) {
                console.log('Decreased!');
                if (this.isSimGetsDeactivated(limitBytes, sim)) {
                    console.log('Disabled');
                    disabled++;
                    disabledList.push(sim);
                }
            }
        });
        this.disabled = disabled;
        this.enabled = enabled;
        this.enabledList = enabledList;
        this.disabledList = disabledList;
    }

    private getLimitInBytes() {
        let result: number | undefined;
        if (this.unsavedLimit) {
            result = Number.parseInt(this.unsavedLimit);
            if (result) {
                if (result === 0) {
                    // 0 means unlimited
                    result = undefined;
                } else {
                    result = this.recalculate(result, this.currentDataUnit, 'B');
                }
            }
        }
        return result;
    }

    protected getQueryParamFilter(params: Sim[]) {
        const filter = {
            'iccid': {
                'filterType': 'text',
                'type': 'in',
                'filter': params.map((value) => value.iccid).join(',')
            }
        };
        return { filter: JSON.stringify(filter) };
    }

    private isLimitIncreased(newLimit: number | undefined, sim: Sim): boolean {
        if (newLimit) {
            return (sim.monthly_data_limit && newLimit > sim.monthly_data_limit);
        } else {
            // undefined == no data limit
            return (sim.monthly_data_limit);
        }
    }

    private isLimitReduced(newLimit: number | undefined, sim: Sim) {
        if (newLimit) {
            return (!sim.monthly_data_limit || newLimit < sim.monthly_data_limit);
        } else {
            // undefined == no data limit
            return false;
        }
    }

    private isSimGetsActivated(newLimit: number | undefined, sim: Sim) {
        if (sim.statusid === StatusConstants.DATA_USAGE_EXHAUSTED) {
            if (newLimit) {
                return (newLimit > sim.monthly_data_volume);
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    private isSimGetsDeactivated(newLimit: number | undefined, sim: Sim) {
        if (sim.statusid === StatusConstants.ACTIVATED) {
            if (newLimit) {
                return (newLimit < sim.monthly_data_volume);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Recalculates the given oldValue (e.g. 1, 1000, 2000) in the old datasize (kB, B, ...) to the new datasize.
     * E.g. recalculates 1000kB to 1MB
     * @param oldValue the old value of the corresponding old datasize
     * @param oldDataSize the old datasize (B, kB, GB, MB)
     * @param newDataSize the new datasize to calculate (B, kB, GB, MB)
     * @private
     */
    private recalculate(oldValue: number, oldDataSize: string, newDataSize: string): number {
        let byteValue = oldValue;
        if (oldDataSize === 'kB') {
            byteValue = byteValue * 1000;
        } else if (oldDataSize === 'MB') {
            byteValue = byteValue * 1000 * 1000;
        } else if (oldDataSize === 'GB') {
            byteValue = byteValue * 1000 * 1000 * 1000;
        }

        if (newDataSize === 'kB') {
            byteValue = byteValue / 1000;
        } else if (newDataSize === 'MB') {
            byteValue = byteValue / 1000 / 1000;
        } else if (newDataSize === 'GB') {
            byteValue = byteValue / 1000 / 1000 / 1000;
        }
        return byteValue;
    }

    protected changeDataUnit(unit: string) {
        this.currentDataUnit = unit;
        this.evaluateTargets();
    }
}
