import { Component, OnInit } from '@angular/core';
import { ApolloError, ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { I18NextPipe } from 'angular-i18next';
import { GraphQLFormattedError } from 'graphql/error';
import {
    AddUsageNotificationMutation,
    DeleteUsageNotificationMutation,
    GetUsageNotificationQuery,
    UpdateUsageNotificationMutation
} from '../../../../graphql/graphql.generated';
import { CustomerData, CustomerService } from '../../service/customer.service';
import { GraphqlService } from '../../service/graphql.service';
import { LoggerService } from '../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.scss'
})

/**
 *  This component is for setting usage notification settings.
 *  At this current iteration, it takes the first usage notification to update it or
 *  add a new one to the stack.
 *  You can delete the current usage notification setting by clearing the email input.
 * */
export class NotificationsComponent implements OnInit {

    public currentUsageNotification: {
        id: number,
        emails: Array<string>,
        percent90: boolean,
        percent100: boolean
    } = { id: 0, emails: [], percent90: false, percent100: false };
    protected emailValidator: { pattern: RegExp, isInvalid: boolean } = {
        pattern: new RegExp('^([\\w-+.]+(?:\\.[\\w-+.]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$'),
        isInvalid: true
    };
    companyName: string = '';
    isLoading: boolean = true;
    isStoring: boolean = false;
    private storedEmails: Array<string> = new Array<string>();
    protected currentOperation: string = 'INVALID';
    private cachedTypes: { percent90: boolean, percent100: boolean } = { percent90: false, percent100: false };

    constructor(private customerService: CustomerService, private graphQlService: GraphqlService, protected i18nextPipe: I18NextPipe,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.customerService.getCurrentCustomer().then((value: CustomerData): void => {
            this.companyName = value.name;
        });
        this.graphQlService.getUsageNotification().then((value: ApolloQueryResult<GetUsageNotificationQuery>): void => {
            if (value.data.getUsageNotification) {
                const { id, emails, percent90, percent100 } = value.data.getUsageNotification;
                this._convertToLocal({ id: id, emails: emails, percent90: percent90, percent100: percent100 });
            } else {
                this.currentUsageNotification.emails = [''];
            }
            LoggerService.info(this.currentOperation);
            // This passes each value of the current usage emails to the cached emails
            this.storedEmails = [...this.currentUsageNotification.emails];
            this.cachedTypes.percent90 = this.currentUsageNotification.percent90;
            this.cachedTypes.percent100 = this.currentUsageNotification.percent100;
            this.isLoading = false;
        });
    }

    /**
     * Checks the current email for displaying an error message in html and the green feedback when valid.
     * @Param email This is the current email from the array to be validated.
     * @Return boolean This is the value of the matched regex or true for the edge case of an empty field.
     * */
    protected isThisEmailValid(email: string | null): boolean {
        if (this.isLoading) {
            return true;
        }

        if (email) {
            return this.emailValidator.pattern.test(email);
        } else return !email && this.currentOperation === 'DELETE';
    }

    /**
     * To check and display if the current email is duplicated in html
     * @Param email the email to be checked
     * @Param i the index of the email
     * @Return boolean if it's not the first entry in the array and there is a duplicate email.
     * */
    protected isEmailDuplicated(email: string | null, i: number): boolean {
        if (email === '') {
            return false;
        }
        return this.firstIndexOf(email, i) && this.duplicatesFound();
    }

    /**
     * Checks for duplicates
     * @Return boolean for duplicates found
     * */
    private duplicatesFound(): boolean {
        const { emails } = this.currentUsageNotification;
        let duplicateFound: number = 0;
        const emailSet: Set<string> = new Set<string>();
        emails.forEach((currentEmail: string): void => {
            if (emailSet.has(currentEmail)) {
                duplicateFound++;
            }
            emailSet.add(currentEmail);
        });
        return duplicateFound > 0;
    }

    /**
     * Checks if the given email is the first entry of that element
     * @Param email the email to be checked
     * @Param i the index of the email
     * @Return boolean if the email it's the first entry of that element
     * */
    private firstIndexOf(email: string | null, i: number): boolean {
        const { emails } = this.currentUsageNotification;
        let firstIndex: number = -1;
        emails.forEach((currentEmail: string, index: number): void => {
            if (currentEmail === email) {
                if (firstIndex < 0) {
                    firstIndex = index;
                }
            }
        });
        return firstIndex < i;
    }

    /**
     * This function calls validate email and then changes the operation according the current status of the input
     * */
    protected onInput(): void {
        this.validateEmail();
        this.changeOperation();
    }

    /**
     * Removes an email from the model with the index passed in html
     * @Param index The index to remove the item bv index
     * */
    protected removeEmail(index: number): void {
        if (index > -1) {
            this.currentUsageNotification.emails.splice(index, 1);
        }
        this.onInput();
    }

    /**
     * This handles the submitting of the current formular.
     * This calls the respective function for the current operation.
     */
    protected onSubmit(): void {
        if (this.isStoring || this.isLoading) {
            return;
        }
        this.isStoring = true;
        switch (this.currentOperation) {
            case 'ADD':
                this.resolveAddUsageNotification();
                break;
            case 'UPDATE':
                this.resolveUpdateUsageNotification();
                break;
            case 'DELETE':
                this.resolveDeleteUsageNotification();
                break;
        }
    }

    /**
     * To add a new email field for the future
     * @Param email the email to be added, default value is empty string for adding a new field in the frontend
     * */
    protected addEmail(email: string = ''): void {
        this.currentUsageNotification.emails.push(email);
        this.onInput();
    }

    //this is used for the edge case of only changing notification types with the same email/s
    /**
     * This function checks if the current preferences are different.
     * @Return boolean
     * */
    private cachedPreferencesNoMatch(): boolean {
        const { percent90, percent100 } = this.currentUsageNotification;
        const cached90: boolean = this.cachedTypes.percent90;
        const cached100: boolean = this.cachedTypes.percent100;
        return cached90 != percent90 || cached100 != percent100;
    }

    /**
     * This method is used to check the length of the cached emails and current emails, and if they don't have the same elements.
     * @return boolean for the operation
     * */
    private cachedEmailsNoMatch(): boolean {
        const { emails } = this.currentUsageNotification;

        if (emails.length === this.storedEmails.length) {
            return !(this.storedEmails.every((email: string) => emails.includes(email)));
        }
        return true;
    }

    /**
     * Change the current operation for the switch statements
     * */
    private changeOperation(): void {
        const { id, emails, percent90, percent100 } = this.currentUsageNotification;
        if (id <= 0 && !this.emailValidator.isInvalid && (percent90 || percent100) && !this.duplicatesFound()) {
            this.currentOperation = 'ADD';
        } else if (id > 0 && emails[0] === '' && !percent100 && !percent90 && emails.length === 1 && !this.duplicatesFound()) {
            this.currentOperation = 'DELETE';
        } else if (id > 0 && !this.emailValidator.isInvalid && (percent90 || percent100) && !this.duplicatesFound() &&
            (this.cachedEmailsNoMatch() || this.cachedPreferencesNoMatch())) {
            this.currentOperation = 'UPDATE';
        } else {
            this.currentOperation = 'INVALID';
        }
        LoggerService.debug('The current operation is [Operation: %s]', this.currentOperation);
    }

    /**
     * This function is needed for ngFor to keep track of item(email) that's being updated
     * */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected trackByIdentityOrIndex(index: number, item: any): number | any {
        if (typeof item === 'object') return item;
        return index;
    }

    /**
     * Checks all the emails and set the validator to true if one or more match for invalid emails is found
     * */
    private validateEmail(): void {
        let invalidEmails: number = 0;
        this.currentUsageNotification.emails.forEach(email => {
            if (email != null) {
                if (!this.emailValidator.pattern.test(email)) {
                    invalidEmails += 1;
                }
            }
        });
        this.emailValidator.isInvalid = invalidEmails > 0;
    }

    /**
     * Display a message according the current operation,
     * then set the current usage notification to the fetched values or to the default values,
     * after that the current operation is set to invalid, and at the end the values for isStoring and isLoading are set to false
     * @Param value This is the fetched value from the promise being handled
     * */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private promiseResolver(value: FetchResult<any>): void {
        if (value.data != undefined || null) {
            let text: string = '';
            let info: string = '';
            switch (this.currentOperation) {
                case 'ADD':
                    info = 'The current usage notification was add: ';
                    text = this.i18nextPipe.transform('settings.notifications.graphql-operations.add');
                    this._convertToLocal(value.data?.addUsageNotification);
                    break;
                case 'UPDATE':
                    info = 'The current usage notification was updated: ';
                    text = this.i18nextPipe.transform('settings.notifications.graphql-operations.update');
                    this._convertToLocal(value.data?.updateUsageNotification);
                    break;
                case 'DELETE':
                    info = 'The current usage notification was deleted: ';
                    text = this.i18nextPipe.transform('settings.notifications.graphql-operations.delete');
                    this.currentUsageNotification = { id: 0, emails: [''], percent90: false, percent100: false };
                    break;
            }
            LoggerService.info(info, this.currentUsageNotification);
            this.toastService.show(ToastMessageBuilder.success().text(text).build());
            this.storedEmails = [...this.currentUsageNotification.emails];
            const { percent90, percent100 } = this.currentUsageNotification;
            this.cachedTypes.percent90 = percent90;
            this.cachedTypes.percent100 = percent100;
            this.currentOperation = 'INVALID';
            this.isStoring = false;
            this.isLoading = false;
        } else {
            let errorText = '';
            if (value.errors) {
                const currentError: GraphQLFormattedError = value.errors[0];
                errorText = currentError.message;
            }
            const text: string = this.i18nextPipe.transform('settings.notifications.graphql-operations.error', { error: errorText });
            this.toastService.show(ToastMessageBuilder.error().text(text).build());
            this.isStoring = false;
            this.isLoading = false;
        }
    }

    private _convertToLocal(data: { id: number, emails: Array<string | null>, percent90: boolean, percent100: boolean }): void {
        this.currentUsageNotification.id = data.id;
        this.currentUsageNotification.emails = new Array<string>();
        data.emails.forEach((email: string | null): void => {
            if (email != null) {
                this.currentUsageNotification.emails.push(email);
            }
        });
        this.currentUsageNotification.percent100 = data.percent100;
        this.currentUsageNotification.percent90 = data.percent90;
    }

    /**
     * This function calls the deleteUsageNotification function and resolves his promise
     * */
    private resolveDeleteUsageNotification(): void {
        LoggerService.info('Trying to delete current usage notification');
        this.graphQlService.deleteUsageNotification(this.currentUsageNotification.id).then(
            (value: FetchResult<DeleteUsageNotificationMutation>): void => {
                this.promiseResolver(value);
            }).catch((error: ApolloError): void => {
            this.graphqlErrorResolver(error);
        });
    }

    /**
     * The following functions handle the promises of the graphql service.
     * This function calls the addUsageNotification function and resolves his promise
     * */
    private resolveAddUsageNotification(): void {
        LoggerService.info('Trying to add usage notification');
        const { id, emails, percent90, percent100 } = this.currentUsageNotification;
        this.graphQlService.addUsageNotification({ id: id, emails: emails, percent90: percent90, percent100: percent100 }
        ).then((value: FetchResult<AddUsageNotificationMutation>): void => {
            this.promiseResolver(value);
        }).catch((error: ApolloError): void => {
            this.graphqlErrorResolver(error);
        });
    }

    /**
     * This function calls the updateUsageNotification function and resolves his promise
     * */
    private resolveUpdateUsageNotification(): void {
        LoggerService.info('Trying to update current usage notification');
        this.graphQlService.updateUsageNotification(this.currentUsageNotification).then(
            (value: FetchResult<UpdateUsageNotificationMutation>): void => {
                this.promiseResolver(value);
            }).catch((error: ApolloError): void => {
            this.graphqlErrorResolver(error);
        });
    }

    /**
     * For handling error coming from the graphql promises
     * @Param error The error catch from the promise
     * */
    private graphqlErrorResolver(error: ApolloError): void {
        const text: string = this.i18nextPipe.transform('settings.notifications.graphql-operations.error', { error: error.message });
        this.toastService.show(ToastMessageBuilder.error().text(text).build());
        this.isStoring = false;
        this.isLoading = false;
    }
}
