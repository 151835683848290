import { Component, Input, OnInit } from '@angular/core';

interface DefaultDataInterface {
    details: string
}

@Component({
  selector: 'app-detail-default',
  templateUrl: './detail-default.component.html',
  styleUrl: './detail-default.component.scss'
})
export class DetailDefaultComponent implements  OnInit {

    @Input({ required: true })
    public data!: DefaultDataInterface;

    public ngOnInit(): void {
        console.log('Data: ', this.data);
    }

}
