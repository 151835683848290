import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'app-table-quicksearch',
    templateUrl: './table-quicksearch.component.html',
    styleUrl: './table-quicksearch.component.scss'
})
export class TableQuicksearchComponent implements OnDestroy {

    @Output()
    public quickSearch = new EventEmitter<string>();

    protected quickSearchText: string = '';
    protected length = 0;

    protected readonly minLength: number = 3;

    private debounce = new Subject();

    constructor(private route: ActivatedRoute, private router: Router) {

        this.debounce.pipe(debounceTime(500)).subscribe(() => {

            // Set or remove query param
            const qs = (this.quickSearchText) ? this.quickSearchText : null;
            this.router.navigate([], {
                queryParams: { qs },
                queryParamsHandling: 'merge'
            }).then();

            if (this.length === 0 || this.length >= 3) {
                this.quickSearch.emit(this.quickSearchText);
            }
        });

        // Read query param
        const queryParams = route.snapshot.queryParams;
        if (queryParams['qs']) {
            this.quickSearchText = queryParams['qs'];
            this.changed(this.quickSearchText);
        }
    }

    protected changed($event: string) {
        this.length = this.visibleLength($event);
        this.debounce.next(null);
    }

    private visibleLength(str: string) {
        return [...new Intl.Segmenter().segment(str)].length;
    }

    ngOnDestroy(): void {
        this.debounce.unsubscribe();
    }
}
