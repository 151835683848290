import { HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
    ApolloClient,
    ApolloLink,
    ApolloQueryResult,
    createHttpLink,
    FetchResult,
    from,
    InMemoryCache,
    NormalizedCacheObject
} from '@apollo/client/core';
import { NetworkError } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils/throwServerError';
import { I18NextPipe } from 'angular-i18next';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Observable, Subject, Subscription } from 'rxjs';
import {
    AddUsageNotificationDocument,
    AddUsageNotificationMutation,
    DeleteUsageNotificationDocument,
    DeleteUsageNotificationMutation,
    GetAvailableStatusTransitionsDocument,
    GetAvailableStatusTransitionsQuery,
    GetAvailableStatusTransitionsQueryVariables,
    GetCustomerDataDocument,
    GetCustomerDataQuery,
    GetCustomerDataQueryVariables,
    GetStatisticsDocument,
    GetStatisticsQuery,
    GetUsageNotificationDocument,
    GetUserConfigDocument,
    GetUserConfigQuery,
    GetUserConfigQueryVariables,
    KeyValueInput,
    ListSimEventsDocument,
    ListSimEventsQuery,
    ListSimEventsQueryVariables,
    ListSimsDocument,
    ListSimsQuery,
    ListSimsQueryVariables,
    SendSmsDocument,
    SendSmsMutation,
    SetUserConfigDocument,
    Sim,
    SimEvent,
    SimEventFilterInput,
    SimEventOrder,
    SimFilterInput,
    UpdatedSimDocument,
    UpdatedSimEventDocument,
    UpdateSimDocument,
    UpdateSimMutation,
    UpdateUsageNotificationDocument,
    UpdateUsageNotificationMutation,
    UsageNotification
} from '../../../graphql/graphql.generated';
import { environment } from '../../environments/environment';
import { AuthService, LOGGED_OUT } from './auth/auth.service';
import { Auth } from './auth/WSimAuth';
import { LoggerService } from './logger';
import { ToastMessageBuilder, ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class GraphqlService implements OnDestroy {

    private readonly currentHttpClient: ApolloClient<NormalizedCacheObject>;
    private currentSubscriptionClient: ApolloClient<NormalizedCacheObject> | undefined;

    private appolloSubscriptionUpdatedSim?: Subscription;
    private appolloSubscriptionUpdatedSimEvents?: Subscription;

    private updatedSimSubscription: Subject<Sim> = new Subject<Sim>();
    private updatedSimEventSubscription: Subject<SimEvent> = new Subject<SimEvent>();
    private clientsResetSubscription: Subject<boolean> = new Subject<boolean>();

    private readonly refreshTokenSubscription;
    private readonly loginStatusSubscription;

    private token?: string;

    private cypressRunning = false;

    public constructor(private authService: AuthService, private toastService: ToastService, private i18nextPipe: I18NextPipe) {
        if (window.Cypress) {
            LoggerService.warn('--> CYPRESS IS RUNNING <--');
            this.cypressRunning = true;
        }

        this.token = Auth.getCurrentUserSession()?.getIdToken().getJwtToken();

        this.loginStatusSubscription = this.authService.subscribeAuthStatus().subscribe((status) => {
            if (status === LOGGED_OUT) {
                this.token = undefined;
                this.resetCache();
                this.resetSubscriptionClient();
            }
        });

        this.refreshTokenSubscription = Auth.getTokenRefreshedSubscription().subscribe((userSession) => {
            this.token = userSession.getIdToken().getJwtToken();
        });

        this.currentHttpClient = this.newClient();
    }

    /**
     * Returns current instance of apollo client. If there is no client present (because of logout etc.)
     * a new one gets created.
     * This client is to be used for queries and mutations in GraphQL!
     * @see environment.graphQlUrl
     */
    public getHttpClient(): ApolloClient<NormalizedCacheObject> {
        return this.currentHttpClient;
    }

    /**
     * Returns the Websocket client which is to be used for subscriptions only! If there is no cached client available, a new client
     * will get created!
     * @see environment.graphQlRealtimeUrl
     */
    public getWebsocketClient(): ApolloClient<NormalizedCacheObject> | undefined {
        if (!this.currentSubscriptionClient) {
            this.currentSubscriptionClient = this.newSubscriptionClient();
        }
        return this.currentSubscriptionClient;
    }

    private resetSubscriptionClient() {
        if (this.currentSubscriptionClient) {
            this.currentSubscriptionClient.stop();
            this.currentSubscriptionClient.clearStore().then();
            this.currentSubscriptionClient = undefined;
        }
    }

    /**
     * Returns a Promise after calling the "getAvailableStatusTransitions" Query on GraphQL.
     */
    public getAvailableStatusTransitions() {
        LoggerService.info(`getAvailableStatusTransitions() entering`);
        return this.getHttpClient().query<GetAvailableStatusTransitionsQuery,
            GetAvailableStatusTransitionsQueryVariables>({
            query: GetAvailableStatusTransitionsDocument,
            returnPartialData: false
        });
    }

    /**
     * The Method used to fetch the statistics data from backend and returns a promise.
     * @Return Promise<ApolloQueryResult<GetStatisticsQuery>>
     * */
    public getStatistics(): Promise<ApolloQueryResult<GetStatisticsQuery>> {
        LoggerService.info(`getStatistics()`);
        return this.getHttpClient().query({
            query: GetStatisticsDocument
        });
    }

    /**
     * Implementation of GraphQL Query getUsageNotification to get the first configured Usage Notification setting
     * */
    public getUsageNotification() {
        LoggerService.info(`getUsageNotification()`);
        return this.getHttpClient().query({
            query: GetUsageNotificationDocument
        });
    }

    /**
     * Add mutation for usage notifications, this takes the array of notification types plus an email to send the notifications.
     * */
    public addUsageNotification(usageNotification: UsageNotification): Promise<FetchResult<AddUsageNotificationMutation>> {
        LoggerService.info(`emails [emails:${usageNotification.emails}] [90%:${usageNotification.percent90}]
        [100%:${usageNotification.percent100}] `);
        const { emails, percent90, percent100 } = usageNotification;
        const variables = {
            emails: emails,
            percent90: percent90,
            percent100: percent100
        };
        return this.getHttpClient().mutate({
            mutation: AddUsageNotificationDocument,
            variables: variables
        });
    }

    /**
     * update mutation for usage notifications, this takes the array of notification types, the id of the usage notification
     * plus an email to send the notifications.
     * */
    public updateUsageNotification(usageNotification: UsageNotification): Promise<FetchResult<UpdateUsageNotificationMutation>> {
        LoggerService.info(`id [id:${usageNotification.id}] emails [emails:${usageNotification.emails}]
        [90%:${usageNotification.percent90}] [100%: ${usageNotification.percent100}] `);
        const { id, emails, percent90, percent100 } = usageNotification;
        const variables = {
            id: id,
            emails: emails,
            percent90: percent90,
            percent100: percent100
        };
        return this.getHttpClient().mutate({
            mutation: UpdateUsageNotificationDocument,
            variables: variables
        });
    }

    /**
     * Delete mutation for usage notifications, the id of the usage notification to be deleted
     * */
    public deleteUsageNotification(id: number): Promise<FetchResult<DeleteUsageNotificationMutation>> {
        LoggerService.info(`id [id:${id}]  `);
        const variables = {
            id: id
        };
        return this.getHttpClient().mutate({
            mutation: DeleteUsageNotificationDocument,
            variables: variables
        });
    }

    public getSimList(limit: number, nextToken: string | undefined, filter: SimFilterInput | undefined, order: object | undefined, quickSearch?: string): Promise<ApolloQueryResult<ListSimsQuery>> {
        LoggerService.info(`getSimList [limit:${limit}] [nextToken:${nextToken}] [filter:${filter}] [order:${order}] [quickSearch: ${quickSearch}]`);

        return this.getHttpClient().query<ListSimsQuery, ListSimsQueryVariables>({
            query: ListSimsDocument,
            returnPartialData: false,
            fetchPolicy: 'network-only',
            variables: {
                filter: filter,
                order: order,
                limit: limit,
                nextToken: nextToken,
                quickSearch: quickSearch
            }
        });
    }

    public getCustomerData(useCache: boolean = true) {
        LoggerService.info(`getCustomerData() entering`);

        return this.getHttpClient().query<GetCustomerDataQuery,
            GetCustomerDataQueryVariables>({
            query: GetCustomerDataDocument,
            returnPartialData: false,
            fetchPolicy: useCache ? 'cache-first' : 'network-only'
        });
    }

    /**
     * Update a specific SIM identified by its icc id
     * @param iccId the iccid of the SIM to be changed
     * @param statusId the statusid to set for the SIM in backend (and provider backend)
     * @param monthly_data_limit new data limit for the SIM
     * @param customField1 the custom field 1 to be stored
     */
    public updateSim(iccId: string, statusId?: number, monthly_data_limit?: number, customField1?: string): Promise<FetchResult<UpdateSimMutation>> {
        LoggerService.info(`updateSim [iccId:${iccId}] [statusId:${statusId}] [monthly_data_limit:${monthly_data_limit}] ` +
            `[customField1:${customField1}]`);
        const variables = {
            iccid: iccId,
            statusid: statusId,
            monthly_data_limit: monthly_data_limit,
            custom_field_1: customField1
        };
        const result = this.getHttpClient().mutate({
            mutation: UpdateSimDocument,
            variables: variables
        });
        result.then((data) => {
            if (data && data.data) {
                this.updatedSimSubscription.next(data.data);
            }
        });
        return result;
    }

    /**
     * Send an SMS to a device
     * @param iccId the iccid of the target SIM
     * @param text the SMS body
     * @param originator the optional originator of the SMS
     */
    public sendSMS(iccId: string, text: string, originator?: string): Promise<FetchResult<SendSmsMutation>> {
        LoggerService.info(`sendSMS [iccId:${iccId}] [text:${text}] [originator:${originator}`);

        const variables = {
            iccid: iccId,
            originator: originator,
            text: text
        };
        return this.getHttpClient().mutate({
            mutation: SendSmsDocument,
            variables: variables
        });
    }

    /**
     * Subscribes to "updatedSim" Mutation. This is used to get notified about sim updates in our backend!
     */
    public subscribeToUpdatedSim() {
        let variables = undefined;
        if (!Auth.isAdmin()) {
            variables = {
                'customerid': Auth.getCurrentCustomerId()
            };
        }
        if (this.cypressRunning) {
            return;
        }
        const observer = this.getWebsocketClient()?.subscribe({
            query: UpdatedSimDocument,
            variables: variables
        });

        this.appolloSubscriptionUpdatedSim = <Subscription>observer?.subscribe((data) => {
            LoggerService.debug('subscribeUpdatedSim. Got data: ', data);

            const mo = data.data.updatedSim.monthly_sms_mo || 0;
            const mt = data.data.updatedSim.monthly_sms_mt || 0;
            data.data.updatedSim.monthly_sms = (mo + mt);

            this.updatedSimSubscription.next(data.data.updatedSim);
        }, (error) => {
            LoggerService.warn('subscribeToUpdatedSim: received error: ', error);
            const errorString = this.getWebsocketError(error);
            if (errorString === 'Connection closed') {
                // we may have got "disconnected" ... reconnect!
                this.resetSubscriptionClient();
                this.subscribeToUpdatedSim();
            } else if (errorString === 'Connection failed: UnauthorizedException' || errorString === 'Timeout disconnect') {
                this.authService.logout();
            } else {
                this.toastService.show(ToastMessageBuilder.error().text('Unable to subscribe to receive SIM card updates! ' + errorString).build());
            }
        });

        LoggerService.debug('subscribeUpdatedSim done with variables: ', variables);
    }

    /**
     * Calls getUserConfig Query and returns the result promise
     * @param useCache define if to use the cache or not (default: true)
     */
    public getUserConfig(useCache: boolean = true) {
        LoggerService.info(`getUserConfig() entering`);

        return this.getHttpClient().query<GetUserConfigQuery,
            GetUserConfigQueryVariables>({
            query: GetUserConfigDocument,
            returnPartialData: false,
            fetchPolicy: useCache ? 'cache-first' : 'network-only'
        });
    }

    /**
     * Stores user config via mutation "setUserConfig"
     * @param userConfig the user configuration (all) to set for the currently logged in user
     */
    public setUserConfig(userConfig: KeyValueInput[]): Promise<FetchResult<SendSmsMutation>> {
        LoggerService.info(`setUserConfig(): entering`);

        const variables = {
            user_config: userConfig
        };

        return this.getHttpClient().mutate({
            mutation: SetUserConfigDocument,
            variables: variables
        });
    }

    /**
     * Returns a Promise<ApolloQueryResult<ListSimEventsQuery>> which in case of successful execution returns a list of sim events for
     * a specific ICC ID.
     * @param iccId the icc id to get the sim events for
     * @param limit the limit (1 - 100)
     * @param nextToken optional token for pagination purposes
     * @param filterOptions to filter the data
     * @param order to provide a sort result
     */
    public getSimEvents(iccId: string, limit: number, nextToken: string | undefined, filterOptions: SimEventFilterInput | undefined, order: SimEventOrder | undefined): Promise<ApolloQueryResult<ListSimEventsQuery>> {
        LoggerService.info(`getSimEvents [iccId:${iccId}] [limit:${limit}] [nextToken:${nextToken}] with filter options: `, filterOptions);

        return this.getHttpClient().query<ListSimEventsQuery, ListSimEventsQueryVariables>({
            query: ListSimEventsDocument,
            returnPartialData: false,
            fetchPolicy: 'network-only',
            variables: {
                iccid: iccId,
                limit: limit,
                filterOptions: filterOptions,
                order: order,
                nextToken: nextToken
            }
        });
    }

    /**
     * Subscribes to "updatedSimEvent" Mutation. This is used to get notified about new sim events for a specific icc id.
     * Currently only 1 subscription is possible for a specific icc id
     * @param iccId the icc id to subscribe for sim event updated for
     */
    public subscribeToUpdateSimEvent(iccId: string) {
        if (this.cypressRunning) {
            return;
        }
        const observer = this.getWebsocketClient()?.subscribe({
            query: UpdatedSimEventDocument,
            variables: {
                iccid: iccId
            }
        });

        this.appolloSubscriptionUpdatedSimEvents = <Subscription>observer?.subscribe((data) => {
            LoggerService.debug('appolloSubscriptionUpdatedSimEvents. Got data: ', data);
            this.updatedSimEventSubscription.next(data.data.updatedSimEvent);
        }, (error) => {
            LoggerService.warn('subscribeToUpdateSimEvent: received error: ', error);
            const errorString = this.getWebsocketError(error);
            if (errorString === 'Connection closed') {
                // we may have got "disconnected" ... reconnect!
                this.resetSubscriptionClient();
                this.subscribeToUpdateSimEvent(iccId);
            } else if (errorString === 'Connection failed: UnauthorizedException' || errorString === 'Timeout disconnect') {
                this.authService.logout();
            } else {
                this.toastService.show(ToastMessageBuilder.error().text('Unable to subscribe to receive SIM card events! ' + errorString).build());
            }
        });

        LoggerService.debug('subscribeToUpdateSimEvent done with variables: ', iccId);
    }

    /**
     * Unsubscribes the current sim event subscription if available.
     */
    public unsubscribeUpdateSimEvent() {
        if (this.appolloSubscriptionUpdatedSimEvents) {
            this.appolloSubscriptionUpdatedSimEvents.unsubscribe();
        }
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getWebsocketError(error: any): string | undefined {
        let errorString = undefined;
        if (error && error.errors && error.errors.length > 0) {
            errorString = error.errors[0].message;
        }
        return errorString;
    }

    private newSubscriptionClient(): ApolloClient<NormalizedCacheObject> | undefined {
        const httpLink = createHttpLink({
            uri: environment.graphQlRealtimeUrl
        });

        const link = ApolloLink.from([
            createAuthLink({
                url: environment.graphQlUrl,
                region: environment.awsRegion,
                auth: {
                    type: 'AMAZON_COGNITO_USER_POOLS',
                    jwtToken: this.token ? this.token : ''
                }
            }),
            createSubscriptionHandshakeLink({
                    url: environment.graphQlUrl,
                    region: environment.awsRegion,
                    auth: {
                        type: 'AMAZON_COGNITO_USER_POOLS',
                        jwtToken: this.token ? this.token : ''
                    }
                },
                httpLink)
        ]);

        if (!this.cypressRunning) {
            return new ApolloClient({
                link,
                cache: this.getCache()
            });
        }
        return undefined;
    }

    private getCache() {
        return new InMemoryCache({
            typePolicies: {
                SIM: {
                    fields: {
                        monthly_sms: {
                            read(monthly_sms, options) {
                                const mo = options.readField('monthly_sms_mo') || 0;
                                const mt = options.readField('monthly_sms_mt') || 0;
                                if (typeof mo === 'number' && typeof mt === 'number') {
                                    return (mo + mt);
                                }
                                return 0;
                            }
                        }
                    }
                }
            }
        });
    }


    // business methods now

    private newClient(): ApolloClient<NormalizedCacheObject> {
        // the URI for the graphQL service can be read from environment!
        const uriLink = createHttpLink({
            uri: environment.graphQlUrl
        });

        // authentication with cognito against AWS appsync
        const authLink = setContext((_, { headers }) => {
            // we need to use the id token - as the JWT token of the id token contains already cognito custom
            // properties and groups etc. otherwise the graphql implementation cannot determine custom properties
            // and / or groups
            return {
                headers: {
                    ...headers,
                    Authorization: this.token
                }
            };
        });

        const error = onError(({ networkError }) => {
            if (networkError && this.isServerError(networkError) && networkError.statusCode === HttpStatusCode.Unauthorized) {
                networkError.message = this.i18nextPipe.transform('error.unauthorized');
                this.authService.logout();
            }
        });

        return new ApolloClient({
            link: from([error,
                authLink,
                uriLink]),
            cache: this.getCache(),
            queryDeduplication: true
        });
    }

    private isServerError(error: NetworkError): error is ServerError {
        return (error as ServerError).name === 'ServerError';
    }

    public ngOnDestroy(): void {
        if (this.appolloSubscriptionUpdatedSim) {
            this.appolloSubscriptionUpdatedSim.unsubscribe();
        }
        if (this.appolloSubscriptionUpdatedSimEvents) {
            this.appolloSubscriptionUpdatedSimEvents.unsubscribe();
        }
        if (this.refreshTokenSubscription) {
            this.refreshTokenSubscription.unsubscribe();
        }
        if (this.loginStatusSubscription) {
            this.loginStatusSubscription.unsubscribe();
        }
    }

    public subscribeUpdatedSim(): Observable<Sim> {
        return this.updatedSimSubscription.asObservable();
    }

    public subscribeClientsReset(): Observable<boolean> {
        return this.clientsResetSubscription.asObservable();
    }

    public subscribeUpdatedSimEvent(): Observable<SimEvent> {
        return this.updatedSimEventSubscription.asObservable();
    }

    public resetCache() {
        if (this.currentHttpClient) {
            this.currentHttpClient.clearStore().then(() => {
                LoggerService.debug('Reset store done.');
            });
        }
    }

}
