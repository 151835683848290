import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

interface DatasizeAmount {
    amount: number,
    dataSize: string
}

@Component({
    selector: 'app-data-volume-edit',
    templateUrl: './data-volume-edit.component.html',
    styleUrls: ['./data-volume-edit.component.scss']
})
export class DataVolumeEditComponent implements ICellEditorAngularComp {

    @ViewChild('limitInput') input: ElementRef | undefined;

    private iccId: string | undefined;
    private oldLimit: number | undefined;
    public availableDataSizes = ['MB', 'GB'];
    public dataVolumeLimit: number | undefined = undefined;
    public saving = false;
    public unsavedLimit: string | undefined = undefined;
    private params: ICellEditorParams | undefined;
    public cellWidth: string | undefined;
    public currentDataSize = 'B';


    constructor(private graphQlService: GraphqlService,
                private toastService: ToastService, private i18Next: I18NextPipe) {
        // the following part resizes this components width according to the size of the column itself
        // this is only done when resizing the grid is final!
    }

    agInit(params: ICellEditorParams): void {
        this.iccId = params.node.id;
        this.params = params;
        this.dataVolumeLimit = params.value;
        this.oldLimit = params.value;
        if (!this.unsavedLimit) {
            this.unsavedLimit = params.value;
        }
        this.cellWidth = params.column.getActualWidth() + 'px';

        // only for initialization!
        if (this.dataVolumeLimit) {
            // now we need to get the next matching, NOT comma separated value (comes from NIFU!)
            const bestMatching = this.getBestMatchingSize(this.dataVolumeLimit);
            this.currentDataSize = bestMatching.dataSize;
            this.unsavedLimit = String(bestMatching.amount);
        } else {
            // if no limit is configured currently set default data size to MB (in most cases this is used)
            this.currentDataSize = 'MB';
        }
    }

    private getBestMatchingSize(sizeInBytes: number): DatasizeAmount {
        let size = this.convertBytesTo(sizeInBytes, 'GB');
        if (Number.isInteger(size)) {
            return { amount: size, dataSize: 'GB' };
        }
        size = this.convertBytesTo(sizeInBytes, 'MB');
        if (Number.isInteger(size)) {
            return { amount: size, dataSize: 'MB' };
        }
        size = this.convertBytesTo(sizeInBytes, 'kB');
        if (Number.isInteger(size)) {
            return { amount: size, dataSize: 'kB' };
        }
        return { amount: sizeInBytes, dataSize: 'B' };
    }

    /**
     * On Key down in the input field
     * @param $event
     */
    onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            this.save();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    /**
     * Stores the data currently configured in this view
     */
    save() {
        if (!this.saving && this.iccId) {
            this.saving = true;
            // we need to recalculate the current datasize back to bytes as we only stores bytes!
            let newLimit = Number(this.unsavedLimit || 0);
            if (newLimit && newLimit > 0) {
                newLimit = this.recalculate(newLimit, this.currentDataSize, 'B');
            }
            this.graphQlService.updateSim(this.iccId, undefined, newLimit, undefined).then((result) => {
                if (result.data) {
                    this.dataVolumeLimit = result.data.updateSim.monthly_data_limit;
                }
            }).catch((error) => {
                this.unsavedLimit = undefined;
                this.dataVolumeLimit = this.oldLimit;
                // show an error message!
                this.toastService.show(ToastMessageBuilder.error().text(this.i18Next.transform('error.dataLimitUpdate', { errorMsg: error.message })).build());
            }).finally(() => {
                this.saving = false;
                this.close();
            });
        }
    }

    public close() {
        this.params?.api.stopEditing(true);
    }

    public getValue(): number | undefined {
        if (this.unsavedLimit) {
            return this.recalculate(Number(this.unsavedLimit), this.currentDataSize, 'B');
        }
        return this.dataVolumeLimit;
    }

    public afterGuiAttached() {
        // force to focus the input field when gui is shown!
        this.input?.nativeElement.focus();
    }

    /**
     * Called from the view, when the dropdown for data size changes
     * @param newDataSize the new data size selected
     */
    public changeDataSize(newDataSize: string): void {
        // const oldSize = this.currentDataSize;
        this.currentDataSize = newDataSize;
        // const limit = Number(this.unsavedLimit);
        //if (limit && limit > 0) {
        //    this.unsavedLimit = String(this.recalculate(limit, oldSize, this.currentDataSize));
        // }
    }

    /**
     * Recalculates the given oldValue (e.g. 1, 1000, 2000) in the old datasize (kB, B, ...) to the new datasize.
     * E.g. recalculates 1000kB to 1MB
     * @param oldValue the old value of the corresponding old datasize
     * @param oldDataSize the old datasize (B, kB, GB, MB)
     * @param newDataSize the new datasize to calculate (B, kB, GB, MB)
     * @private
     */
    private recalculate(oldValue: number, oldDataSize: string, newDataSize: string): number {
        let byteValue = oldValue;
        if (oldDataSize === 'kB') {
            byteValue = byteValue * 1000;
        } else if (oldDataSize === 'MB') {
            byteValue = byteValue * 1000 * 1000;
        } else if (oldDataSize === 'GB') {
            byteValue = byteValue * 1000 * 1000 * 1000;
        }

        if (newDataSize === 'kB') {
            byteValue = byteValue / 1000;
        } else if (newDataSize === 'MB') {
            byteValue = byteValue / 1000 / 1000;
        } else if (newDataSize === 'GB') {
            byteValue = byteValue / 1000 / 1000 / 1000;
        }
        return byteValue;
    }

    /**
     * Converts the given bytes to the given datasize (B, kB, MB, GB)
     * @param sizeInBytes
     * @param dataSize
     * @private
     */
    private convertBytesTo(sizeInBytes: number, dataSize: string): number {
        if (dataSize === 'B') {
            return sizeInBytes;
        }
        if (dataSize === 'kB') {
            return sizeInBytes / 1000;
        }
        if (dataSize === 'MB') {
            return sizeInBytes / 1000 / 1000;
        }
        // GB
        return sizeInBytes / 1000 / 1000 / 1000;
    }

}
