import { Component, inject, Input, ViewChild } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { DxDataGridComponent, DxDateBoxComponent, DxPopoverComponent, DxRadioGroupComponent } from 'devextreme-angular';
import { ValueChangedEvent } from 'devextreme/ui/date_box';
import { OptionChangedEvent } from 'devextreme/ui/radio_group';
import { DateTime } from 'luxon';
import { Auth } from '../../../../../service/auth/WSimAuth';

interface StartStop {
    start: number;
    stop: number;
}

@Component({
    selector: 'app-timestamp-header',
    templateUrl: './timestamp-header.component.html',
    styleUrl: './timestamp-header.component.scss'
})
export class TimestampHeaderComponent {

    protected filterActive = false;

    @Input({ required: true })
    public dataGrid!: DxDataGridComponent;

    @ViewChild('radioGroup', { static: false })
    private radioGroup?: DxRadioGroupComponent;

    @ViewChild('popover', { static: false })
    protected popover?: DxPopoverComponent;

    private readonly TODAY = 1;
    private readonly YESTERDAY = 2;
    private readonly THIS_WEEK = 3;
    private readonly LAST_WEEK = 4;
    private readonly THIS_MONTH = 5;
    private readonly LAST_MONTH = 6;
    protected readonly RANGE = 7;

    protected i18next = inject(I18NextPipe);

    private startSelected: DateTime | undefined;
    private stopSelected: DateTime | undefined;

    protected dateFormat = this.i18next.transform('timestampFilter.dateFormat');

    // I don't like that - because we have already defined the wsim blue light color - but it isn't simply accessible in typescript
    private filterSelectedColor = '#edf4fa'; // wsim-blue-light
    private defaultColor = '#e9e9ef'; // wsim-gray-2

    protected dataSource = [
        { text: this.i18next.transform('timestampFilter.today'), value: this.TODAY },
        { text: this.i18next.transform('timestampFilter.yesterday'), value: this.YESTERDAY },
        { text: this.i18next.transform('timestampFilter.thisWeek'), value: this.THIS_WEEK },
        { text: this.i18next.transform('timestampFilter.lastWeek'), value: this.LAST_WEEK },
        { text: this.i18next.transform('timestampFilter.thisMonth'), value: this.THIS_MONTH },
        { text: this.i18next.transform('timestampFilter.lastMonth'), value: this.LAST_MONTH },
        { text: 'range', value: this.RANGE }
    ];

    protected openFilter($event: MouseEvent) {
        $event.stopPropagation();
        this.popover?.instance.toggle(true);
    }

    private activateFilter() {
        this.filterActive = true;
        this.popover?.instance.toggle(false);
        this.getColumnHeaderHtmlElement().style.backgroundColor = this.filterSelectedColor;
    }

    public deleteFilter() {
        this.popover?.instance.toggle(false);
        // reset filter by setting empty array
        this.removeDataGridFilter();
        this.filterActive = false;
        this.radioGroup?.instance.clear();
        this.getColumnHeaderHtmlElement().style.backgroundColor = this.defaultColor;
    }

    protected optionChanged($event: OptionChangedEvent) {
        if ($event.name == 'value') {
            let startStop: StartStop | undefined;
            switch ($event.value?.value) {
                case this.TODAY: {
                    const date = DateTime.now().setZone(Auth.getUserTimeZone());
                    startStop = {
                        start: date.startOf('day').toMillis(),
                        stop: date.endOf('day').toMillis()
                    };
                    break;
                }
                case this.YESTERDAY: {
                    const yesterday = DateTime.now().setZone(Auth.getUserTimeZone()).minus({ days: 1 });
                    startStop = {
                        start: yesterday.startOf('day').toMillis(),
                        stop: yesterday.endOf('day').toMillis()
                    };
                    break;
                }
                case this.THIS_MONTH: {
                    const now = DateTime.now().setZone(Auth.getUserTimeZone());
                    startStop = {
                        start: now.startOf('month').toMillis(),
                        stop: now.endOf('month').toMillis()
                    };
                    break;
                }
                case this.LAST_MONTH: {
                    const lastMonth = DateTime.now().setZone(Auth.getUserTimeZone()).startOf('month').minus({ month: 1 });
                    startStop = {
                        start: lastMonth.startOf('month').toMillis(),
                        stop: lastMonth.endOf('month').toMillis()
                    };
                    break;
                }
                case this.THIS_WEEK: {
                    const now = DateTime.now().setZone(Auth.getUserTimeZone());
                    startStop = {
                        start: now.startOf('week').toMillis(),
                        stop: now.endOf('week').toMillis()
                    };
                    break;
                }
                case this.LAST_WEEK: {
                    const lastWeek = DateTime.now().setZone(Auth.getUserTimeZone()).minus({ weeks: 1 });
                    startStop = {
                        start: lastWeek.startOf('week').toMillis(),
                        stop: lastWeek.endOf('week').toMillis()
                    };
                    break;
                }
                case this.RANGE: {
                    this.applyRangeIfPossible();
                    break;
                }
            }
            if (startStop) {
                this.setDataGridFilter(startStop.start, startStop.stop);
            }
        }
    }

    protected setDataGridFilter(start: number, stop: number) {
        const newTimestampFilter = ['startTimestamp', start, 'stopTimestamp', stop];
        let currentFilter = this.dataGrid.instance.getCombinedFilter();
        if (currentFilter) {
            if (currentFilter.indexOf('startTimestamp') > -1) {
                currentFilter.splice(currentFilter.indexOf('startTimestamp'), 4);
            }
            currentFilter.push(...newTimestampFilter);
        } else {
            currentFilter = newTimestampFilter;
        }
        this.dataGrid.instance.filter(currentFilter);
        this.activateFilter();
    }

    protected removeDataGridFilter() {
        const currentFilter = this.dataGrid.instance.getCombinedFilter();
        if (currentFilter && currentFilter.indexOf('startTimestamp') > -1) {
            currentFilter.splice(currentFilter.indexOf('startTimestamp'), 4);
            this.dataGrid.instance.filter(currentFilter);
        }
    }

    protected handleValueChange($event: ValueChangedEvent, type: 'start' | 'stop') {
        if ((<Date>$event.value)) {
            if (type === 'start') {
                this.startSelected = DateTime.fromMillis((<Date>$event.value).getTime()).setZone(Auth.getUserTimeZone()).startOf('day');
            } else if (type === 'stop') {
                this.stopSelected = DateTime.fromMillis((<Date>$event.value).getTime()).setZone(Auth.getUserTimeZone()).endOf('day');
            }
            this.applyRangeIfPossible();
        }
    }

    private applyRangeIfPossible() {
        if (this.startSelected && this.stopSelected) {
            if (this.startSelected < this.stopSelected) {
                this.setDataGridFilter(this.startSelected.toMillis(), this.stopSelected.toMillis());
            }
        }
    }

    protected openDateBox(dateComponent: DxDateBoxComponent) {
        dateComponent.instance.open();
    }

    private getColumnHeaderHtmlElement(): HTMLElement {
        return <HTMLElement>document.querySelectorAll('[id*="dx-col"]')[0];
    }

}
