import { Component, inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { SimEvent, SimEventFilterInput, SimEventOrder } from '../../../../../graphql/graphql.generated';
import { GraphqlService } from '../../../service/graphql.service';
import { LoggerService } from '../../../service/logger';
import { DevxFilterConverter } from '../devx-filter-converter';
import { TimestampHeaderComponent } from './custom-cell-header/timestamp-header/timestamp-header.component';


@Component({
    selector: 'app-sim-detail-event-table-devex',
    templateUrl: './sim-detail-event-table-devex.component.html',
    styleUrl: './sim-detail-event-table-devex.component.scss'
})
export class SimDetailEventTableDevexComponent implements OnChanges {

    @Input() public iccid?: string;

    private graphql = inject(GraphqlService);
    protected i18next = inject(I18NextPipe);

    private readonly PAGE_SIZE = 50;

    protected dataSource: DataSource;

    private oldIccid?: string;
    private readonly store: CustomStore;
    private nextToken?: string | undefined;
    private updatedSimEvent$?: Subscription;
    protected eventAmount?: number;

    protected detailedEvents = ['presence', 'lifeCycle', 'portalInternalLifeCycle', 'consumption', 'usageNotification'];

    @ViewChild('gridView', { static: false })
    protected dataGrid: DxDataGridComponent | undefined;

    @ViewChild('timestampHeader', { static: false })
    protected timestampHeader: TimestampHeaderComponent | undefined;

    @ViewChild('detailsHeader', { static: false })
    protected detailsHeader: TimestampHeaderComponent | undefined;


    public constructor() {
        this.store = new CustomStore({
            key: 'id',
            load: (loadOptions: LoadOptions) => {
                console.log('CustomStore load', loadOptions, this.nextToken);
                if (!this.iccid) return {};

                // Reset nextToken if reading from beginning
                if (loadOptions.skip === 0 && this.nextToken) this.nextToken = undefined;

                const order = this.getSimEventOrder(loadOptions);
                const filter = this.getSimEventFilter(loadOptions);

                const limit = loadOptions.take || this.PAGE_SIZE;

                return this.graphql.getSimEvents(this.iccid, limit, this.nextToken, filter, order).then((response) => {
                    this.nextToken = response.data.listSimEvents?.nextToken || undefined;
                    this.eventAmount = response.data.listSimEvents?.totalEvents;
                    console.log('Got events: ', response.data.listSimEvents);
                    return {
                        data: response.data.listSimEvents?.items,
                        totalCount: response.data.listSimEvents?.items?.length
                    };
                });
            }
        });
        this.dataSource = new DataSource({
            store: this.store,
            reshapeOnPush: true,
            pageSize: this.PAGE_SIZE,
            map: (item) => SimDetailEventTableDevexComponent.convertSimEvent(item)
        });
    }

    private getSimEventFilter(loadOptions: LoadOptions) {
        const filter: SimEventFilterInput = {};
        console.log('got filter: ', loadOptions.filter);
        if (loadOptions.filter) {
            if (loadOptions.filter.indexOf('startTimestamp') > -1) {
                filter.timestampMilliseconds = {
                    'between': [
                        loadOptions.filter[loadOptions.filter.indexOf('startTimestamp') + 1],
                        loadOptions.filter[loadOptions.filter.indexOf('stopTimestamp') + 1]
                    ]
                };
            }
            if (loadOptions.filter.indexOf('eventTypes') > -1) {
                const eventTypes = loadOptions.filter[loadOptions.filter.indexOf('eventTypes') + 1];
                if (eventTypes.length == 1) {
                    filter.type = { 'eq': eventTypes[0] };
                } else {
                    filter.type = { 'in': eventTypes };
                }
            }
        }
        return filter;
    }


    private getSimEventOrder(loadOptions: LoadOptions) {
        const order: SimEventOrder = {};
        if (loadOptions && loadOptions.sort) {
            let sort;
            if (Array.isArray(loadOptions.sort)) {
                sort = loadOptions.sort.at(0);
            } else {
                sort = loadOptions.sort;
            }
            if (sort && typeof sort === 'object' && 'selector' in sort && sort.selector == 'timestampMilliseconds') {
                order.timestampMilliseconds = sort.desc ? 'desc' : 'asc';
            }
        }
        return order;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['iccid']) {
            this.onIccidChange();
        }
    }

    private onIccidChange() {
        if (!this.iccid) return;
        this.nextToken = undefined;
        this.timestampHeader?.deleteFilter();
        this.detailsHeader?.deleteFilter();
        this.store.clearRawDataCache();
        if (this.oldIccid && this.oldIccid !== this.iccid) {
            this.dataGrid?.instance.pageIndex(0);
            this.dataSource.reload();
        }
        this.graphql.unsubscribeUpdateSimEvent();
        this.graphql.subscribeToUpdateSimEvent(this.iccid);
        if (this.updatedSimEvent$) this.updatedSimEvent$.unsubscribe();
        this.updatedSimEvent$ = this.graphql.subscribeUpdatedSimEvent().subscribe((data) => {
            LoggerService.debug('Receive a updated sim event with data: %s', data);
            this.addReceivedSimEvent(data);
        });
        this.oldIccid = this.iccid;
    }

    /**
     * Method which is called from our subscription to incoming new events.
     * @param data
     * @private
     */
    private addReceivedSimEvent(data: SimEvent) {
        this.nextToken = undefined;
        if (data.jsonData) {
            let appendData = true;
            const convertedData = SimDetailEventTableDevexComponent.convertSimEvent(data);
            let index = 0;  // default: add on top!
            const currentSortOrder = this.getCurrentSortOrder();
            const currentFilter = this.dataGrid?.instance.getCombinedFilter();
            // check if table filter set matches the incoming event!
            if (currentFilter) {
                appendData = false;
                const filterConverter = new DevxFilterConverter(currentFilter);
                if (filterConverter.hasTimestampConfigured) {
                    if (convertedData.timestampMilliseconds < filterConverter.startTimestamp ||
                        convertedData.timestampMilliseconds > filterConverter.stopTimestamp) {
                        appendData = false;
                    }
                }
                if (filterConverter.hasEventTypesConfigured && filterConverter.eventTypes?.indexOf(convertedData.type) == -1) {
                    appendData = false;
                }
            }
            // check where to add the new event (at the end when asc)
            if (appendData && currentSortOrder?.sortBy == 'timestampMilliseconds' && currentSortOrder?.order == 'asc') {
                index = -1;
                // When order is timestamp ascending (and there still is a token) - this means the user has not scrolled
                // to the end of the list.
                // We should not append this event to the end (because it will be loaded anyway when scrolling)
                if (this.nextToken) {
                    appendData = false;
                }
            }

            // when we have filter(s) we need to check if the incoming data fragment matches the filter!
            // If the incoming data doesn't match the filter, we do not append the data!
            if (appendData) {
                this.store.push([{ type: 'insert', key: data.id, data: convertedData, index: index }]);
                if (this.eventAmount) {
                    this.eventAmount += 1;
                }
            }
        }
    }

    private getCurrentSortOrder() {
        const sorting = this.dataSource.sort();
        let sort;
        if (Array.isArray(sorting)) {
            sort = sorting.at(0);
        } else {
            sort = sorting;
        }
        if (sort && typeof sort === 'object' && 'selector' in sort && sort.selector == 'timestampMilliseconds') {
            return {
                sortBy: sort.selector,
                order: sort.desc ? 'desc' : 'asc'
            };
        }
        return undefined;
    }

    private static convertSimEvent(simEvent: SimEvent) {
        return {
            ...simEvent,
            json: (simEvent.jsonData ? JSON.parse(simEvent.jsonData) : undefined)
        };
    }

    /*
    protected onCellPrepared(e: CellPreparedEvent) {
        if (e.rowType == 'header') {
            if (e.column.dataField === 'timestamp') {
                const parent = e.cellElement;
                console.log(parent.children);
                const indicator = parent.children.item(0);
                // if (indicator) indicator.innerHTML = indicator.innerHTML + '<i class="ps-1 fa-solid fa-filter filter-not-selected"></i>';
                // if (indicator) indicator.innerHTML = indicator.innerHTML + '<app-timestamp-header [dataGrid]="gridView"></app-timestamp-header>';
                console.log('OnCellPrepared: ', e, indicator);
            }
        }
    }
     */
}
