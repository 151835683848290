import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { Sim, UpdateSimMutation } from '../../../../graphql/graphql.generated';
import { GraphqlService } from '../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../service/toast.service';

@Component({
    selector: 'app-modal-bulk-change-sim-custom-field1',
    templateUrl: './modal-bulk-change-sim-custom-field1.component.html',
    styleUrl: './modal-bulk-change-sim-custom-field1.component.scss'
})
export class ModalBulkChangeSimCustomField1Component {

    // for input from another component
    @Input()
    public selectedSimCards?: Sim[];

    public inProgress = false;

    public saveEnabled = true;

    public customField1Value: string = '';

    public errorMsg?: string;

    @ViewChild('content')
    private content: TemplateRef<never> | undefined;

    private modal?: NgbModalRef;

    constructor(private graphQlService: GraphqlService, private toastService: ToastService,
                private i18nextPipe: I18NextPipe, private modalService: NgbModal) {
    }


    public open() {
        this.modal = this.modalService.open(this.content, { ariaLabelledBy: 'bulkChangeSimCustomField1Label' });
        this.modal.result.then(() => {
            this.customField1Value = '';
            this.saveEnabled = true;
        });
        return this.modal;
    }


    public commitChange() {
        if (this.inProgress) {
            return;
        }
        this.inProgress = true;
        // collect the promises and wait with closing the dialog until we sent out all ...
        const promises: Promise<FetchResult<UpdateSimMutation>>[] = [];
        const successfulArray: string[] = [];
        if (this.selectedSimCards) {
            this.selectedSimCards.forEach((simCard) => {
                promises.push(this.graphQlService.updateSim(simCard.iccid, undefined, undefined, this.customField1Value));
            });

            // very important: promises are resolved in order. so we can access the original array for icc id mutation was called for:
            // https://stackoverflow.com/a/67274260
            Promise.allSettled(promises).then((results: PromiseSettledResult<FetchResult<UpdateSimMutation>>[]) => {
                results.forEach((promiseResult, index) => {
                    if (promiseResult.status === 'fulfilled') {
                        if (promiseResult.value.data?.updateSim.iccid) {
                            successfulArray.push(promiseResult.value.data?.updateSim.iccid);
                        }
                    } else {
                        if (this.selectedSimCards) {
                            const iccId = this.selectedSimCards[index].iccid;
                            const text = this.i18nextPipe.transform('bulkCustomField1Change.errorChangeCustomField1ForIccId', {
                                iccId: iccId,
                                error: promiseResult.reason?.message
                            });
                            this.toastService.show(ToastMessageBuilder.error().text(text).build());
                        }
                    }
                });
                this.inProgress = false;
                if (successfulArray.length > 0) {
                    const text = this.i18nextPipe.transform('bulkCustomField1Change.successChangeCustomField1', { count: successfulArray.length });
                    this.toastService.show(ToastMessageBuilder.success().text(text).build());
                }
                this.modal?.close();
            });
        }
    }

    public onKeyUpEnter() {
        if (this.inProgress) {
            return;
        }
        this.onChangeCustomField1();
        if (this.saveEnabled) {
            this.commitChange();
        }
    }

    public onChangeCustomField1() {
        this.errorMsg = undefined;
        this.saveEnabled = true;
        if (this.customField1Value && this.customField1Value.length > 255) {
            this.errorMsg = this.i18nextPipe.transform('bulkCustomField1Change.errorOnly255CharsAllowed');
            this.saveEnabled = false;
        }
    }

}
