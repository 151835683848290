<div class="row pt-2">
  <div class="col">
    <h4 class="text-secondary">{{ 'events' | i18nextCap }}</h4>
  </div>
  <div class="col m-auto text-end">
    {{ 'live-update' | i18nextCap }} <i class="fa-regular fa-circle-play fa-beat-fade text-primary"
                                        style="--fa-animation-duration: 3s;"></i>
  </div>
</div>

<dx-data-grid
  #gridView
  id="simEvents"
  class="h-50"
  [dataSource]="dataSource"
  [showBorders]="true"
  [showRowLines]="true"
  [allowColumnResizing]="true"
  [wordWrapEnabled]="true"
  [remoteOperations]="true"
  [rowAlternationEnabled]="true"
  [repaintChangesOnly]="true"
>
  <dxo-scrolling mode="infinite"></dxo-scrolling>
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <dxi-column dataField="timestampMilliseconds" dataType="datetime" sortOrder="desc"
              [format]="{ dateStyle: 'short', timeStyle: 'medium'}"
              [caption]="this.i18next.transform('table.header.timestamp', { format: 'Cap'})"
              [allowHeaderFiltering]="false"
              [width]="140" headerCellTemplate="timestampMillisecondsHeaderCellTemplate">
  </dxi-column>

  <div *dxTemplate="let data of 'timestampMillisecondsHeaderCellTemplate'">
    <app-timestamp-header [dataGrid]="gridView" #timestampHeader></app-timestamp-header>
  </div>

  <dxi-column dataField="details" [allowSorting]="false" [allowHeaderFiltering]="false"
              [caption]="this.i18next.transform('table.header.details', { format: 'Cap'})"
              cellTemplate="cellTemplateDetails" headerCellTemplate="detailsHeaderCellTemplate">
  </dxi-column>

  <div *dxTemplate="let data of 'detailsHeaderCellTemplate'">
    <app-details-header [dataGrid]="gridView" #detailsHeader></app-details-header>
  </div>



  <dxi-column dataField="json.operator" [allowSorting]="false"
              [caption]="this.i18next.transform('table.header.operator', { format: 'Cap'})"
              [allowFiltering]="false"
              [width]="140"
  ></dxi-column>
  <dxi-column dataField="json.countryCode" [allowSorting]="false" cellTemplate="cellTemplateCountry"
              [caption]="this.i18next.transform('table.header.country', { format: 'Cap'})"
              [allowFiltering]="false"
              [width]="140"
  ></dxi-column>

  <div *dxTemplate="let data of 'cellTemplateCountry'">
    {{ data.data.json.countryCode | countryCodeToFlag }}
    {{ data.data.json.countryCode | countryCodeToName | async }}
  </div>

  <div *dxTemplate="let data of 'cellTemplateDetails'">
    <app-detail-presence [data]="data.data" *ngIf="data.data.type === 'presence'">
    </app-detail-presence>
    <app-detail-lifecycle [data]="data.data"
                          *ngIf="data.data.type === 'lifeCycle' || data.data.type === 'portalInternalLifeCycle'">
    </app-detail-lifecycle>
    <app-detail-consumption [data]="data.data" *ngIf="data.data.type === 'consumption'">
    </app-detail-consumption>
    <app-detail-usage-notification [data]="data.data" *ngIf="data.data.type === 'usageNotification'">
    </app-detail-usage-notification>
    <app-detail-default [data]="data.data" *ngIf="detailedEvents.indexOf(data.data.type)=== -1">
    </app-detail-default>
  </div>

</dx-data-grid>

<div data-cy="sim-detail-event-total">{{ 'table.events.summary' | i18next: {amount: this.eventAmount} }}</div>
