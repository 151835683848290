import { Component, Input } from '@angular/core';

interface JsonDataInterface {
    json: {
        emails: string[],
        notificationType: number,
        dataAmount: number,
        dataLimit: number
    };
}

@Component({
    selector: 'app-detail-usage-notification',
    templateUrl: './detail-usage-notification.component.html',
    styleUrl: './detail-usage-notification.component.scss'
})
export class DetailUsageNotificationComponent {

    @Input({ required: true })
    public data!: JsonDataInterface;

}
