<div class="d-flex flex-row">
  <div class="ps-2"><i [class]="iconClass"></i></div>
  <div class="ps-1">
    <div class="row">
      <div *ngIf="i18NextKeyForIcon">{{ 'presence.' + i18NextKeyForIcon | i18next }}</div>
    </div>
    <div class="row" *ngIf="dataSessionActive">
      <div class="d-flex flex-row">{{ 'presence.carrier' | i18next: {data: data.json.operator} }}</div>
    </div>
    <div class="row" *ngIf="dataSessionActive">
      <div class="d-flex flex-row">{{ 'presence.ip-address' | i18next: {data: data.json.ip} }}</div>
    </div>
  </div>
</div>
