<div class="d-flex align-items-center" xmlns="http://www.w3.org/1999/html">
  <div class="flex-grow-1">{{ 'table.header.timestamp' | i18next: {format: 'Cap'} }}</div>
  <i #iconFilterDetails class="ps-1 fa-solid fa-filter" [ngClass]="(filterActive)?'filter-selected':'filter-not-selected'"
     (click)="openFilter($event)"></i>
</div>


<!-- popover opening on clicking the filter icon -->
<dx-popover
  #popover
  [hideOnOutsideClick]="true"
  [height]="'auto'"
  [width]="'auto'"
  [shading]="false"
  [showCloseButton]="false"
  [showTitle]="false">

  <!-- set position of the popover with offset to have the arrow more up than center! -->
  <dxo-position my="center left" at="right" [of]="iconFilterDetails" offset="0 70"></dxo-position>

  <div class="d-flex mb-2"><b>Filter</b></div>
  <!-- filter elements -->
  <div class="pt-2 ps-2">
    <div class="row" *ngFor="let item of filterModel">
      <div class="form-check align-middle">
        <input class="form-check-input"
               type="checkbox"
               id="checkbox_{{item.id}}"
               [(ngModel)]="item.checked"
               [checked]="item.checked"
               (change)="optionChanged($event)">
        <label class="form-check-label align-middle" for="checkbox_{{item.id}}">
          {{ item.text }}
        </label>
      </div>
    </div>
  </div>
  <!-- clear filter button -->
  <div class="pt-2 d-flex justify-content-center">
    <button class="btn btn-secondary btn-sm btn-block w-100 btn-border" type="button" aria-expanded="false"
            (click)="deleteFilter()">
      <i class="fa-solid fa-trash-can pe-2"></i> {{ 'deleteFilter' | i18next }}
    </button>
  </div>
</dx-popover>


