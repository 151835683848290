/**
 * The datasource for the ag-grid table. We need a datasource (only) when using infinite scrolling,
 * otherwise we can use normal data arrays.
 *
 * This datasource only has 1 public method - getRows which is called by ag-grid framework when
 * data is requested.
 *
 * The datasource also contains the requests to graphQL as well as the response methods for querying data.
 */
import { ApolloError } from '@apollo/client/core';
import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { ListSimEventsQuery } from '../../../../../graphql/graphql.generated';
import { GraphqlService } from '../../../service/graphql.service';
import { LoggerService } from '../../../service/logger';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

export class SimEventDatasource implements IDatasource {

    private _nextToken: string | undefined;
    private _pageSize = 100;
    private gridApi?: GridApi;
    private iccid: string | undefined;

    public constructor(private graphQlClient: GraphqlService, private toastService: ToastService) {
    }

    public setIccid(iccid: string) {
        this.iccid = iccid;
    }

    public setGridApi(gridApi?: GridApi) {
        this.gridApi = gridApi;
    }

    public getRows(params: IGetRowsParams): void {
        LoggerService.debug('SimEventsDatasource.getRows(): params are: ', params);

        if (!this.iccid) {
            return;
        }

        // start row resets to zero when we start a new table model
        if (params.startRow == 0) {
            this._nextToken = undefined;
            this.gridApi?.showLoadingOverlay();
        }

        this.graphQlClient.getSimEvents(
            this.iccid,
            this._pageSize,
            this._nextToken,
            undefined,
            undefined)
            .then(({ data, error }) => this.onDataReceived(data, error, params))
            .catch((error) => this.onDataError(error, params));
    }

    /**
     * This method is called when we receive data from the graphQL call
     * @param data the data itself coming from the graphQL service
     * @param error if any error occurred
     * @param paramsCallback the callback for the result that has to be used (append data to table)
     * @private
     */
    private onDataReceived(data: ListSimEventsQuery | undefined, error: Error | undefined,
                           paramsCallback: IGetRowsParams) {
        LoggerService.debug('onSimListDataReceived(): got valueChanges -> ', data, paramsCallback);
        if (!error) {
            this.gridApi?.hideOverlay();
            if (data && !data.listSimEvents) {
                if (!this._nextToken) {
                    LoggerService.debug('onSimListDataReceived(): received empty list. No data!');
                    // this was an initial request
                    paramsCallback.successCallback([], 0);
                }
                this._nextToken = undefined;
            } else if (data && data.listSimEvents?.nextToken && data.listSimEvents?.nextToken !== '') {
                LoggerService.debug('onSimListDataReceived(): setting next token: ', data.listSimEvents.nextToken);
                this._nextToken = data.listSimEvents.nextToken;
            } else {
                this._nextToken = undefined;
            }
            if (data && data.listSimEvents?.items && paramsCallback) {
                if (this._nextToken) {
                    LoggerService.debug(`We have a next token. So not reached the end of the list!`);
                    paramsCallback.successCallback(data.listSimEvents.items);
                } else {
                    const lastRow = paramsCallback.startRow + data.listSimEvents.items.length;
                    LoggerService.debug(`We have no next token. Sending callback row data with lastRow: ${lastRow}`);
                    paramsCallback.successCallback(data.listSimEvents.items, lastRow);
                }
            } else if (data && !data.listSimEvents) {
                LoggerService.debug(`We got data but no SIM list. Sending callback with empty list, lastRow: ${paramsCallback.startRow}`);
                paramsCallback.successCallback([], paramsCallback.startRow);
            }
        } else {
            this.toastService.show(ToastMessageBuilder.error().text('Backend Error: ' + error?.message).build());
        }
    }

    private onDataError(error: ApolloError | undefined, params: IGetRowsParams) {
        if (error) {
            this.toastService.show(ToastMessageBuilder.error().text('Backend Error: ' + error?.message).build());
            params.failCallback();
        }
    }

}
