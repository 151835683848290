import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-sms-amount',
    templateUrl: './sms-amount.component.html',
    styleUrls: ['./sms-amount.component.scss']
})
export class SmsAmountComponent implements ICellRendererAngularComp {

    public smsCount = 0;
    public smsCountMo = 0;
    public smsCountMt = 0;
    public showIcon = true;
    private params: ICellRendererParams | undefined;


    public agInit(params: ICellRendererParams): void {
        this.params = params;
        if (!this.params.node || !this.params.node.id) {
            this.showIcon = false;
        }
        this.updateCounter(params);
    }

    public refresh(params: ICellRendererParams): boolean {
        this.updateCounter(params);
        return true;
    }

    private updateCounter(params: ICellRendererParams) {
        if (params.data) {
            this.smsCountMo = params.data.monthly_sms_mo || 0;
            this.smsCountMt = params.data.monthly_sms_mt || 0;
            this.smsCount = params.value;
        }
    }

    public startEditMode() {
        if (this.params?.column && this.params?.node.rowIndex != undefined) {
            this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: this.params.column });
        }
    }
}
