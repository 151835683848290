import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberWithError } from 'libphonenumber-js';

@Pipe({
    name: 'msisdnFormat'
})
export class MsisdnFormatPipe implements PipeTransform {

    public transform(value: string): string {
        if (value) {
            if (!value.startsWith('+') && value.length > 0) {
                value = '+' + value;
            }
            try {
                const phoneNumber = parsePhoneNumberWithError(value);
                if (phoneNumber) {
                    return phoneNumber.formatInternational();
                }
            } catch (err) {
                /* empty */
            }
        }
        return value;
    }

}
