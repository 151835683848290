import { Pipe, PipeTransform } from '@angular/core';
import i18next from 'i18next';
import prettyBytes, { Options } from 'pretty-bytes';

@Pipe({
    name: 'byteFormat'
})
export class ByteFormatPipe implements PipeTransform {
    transform(bytes: number | undefined, options: Options = {
        locale: i18next.language,
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    }): string | number | undefined {
        if (bytes) {
            return prettyBytes(bytes, options);
        } else {
            return bytes;
        }
    }
}
