import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

/**
 * To define the custom params coming from the column definitions
 * */
export interface ICustomHeaderParams {
    icon: string;
}

@Component({
    selector: 'app-checkbox-clear-selector',
    templateUrl: './checkbox-clear-selector.component.html',
    styleUrl: './checkbox-clear-selector.component.scss'
})
export class CheckboxClearSelectorComponent implements IHeaderAngularComp {
    public params!: IHeaderParams & ICustomHeaderParams;

    agInit(params: IHeaderParams & ICustomHeaderParams): void {
        this.params = params;
    }

    /**
     * @Params the new params after the new event is listened
     * */
    refresh(params: IHeaderParams & ICustomHeaderParams): boolean {
        this.params = params;
        return false;
    }

    /**
     * Calls the api deselectAll function
     * */
    public clearSelection(): void {
        this.params.api.deselectAll();
    }

    /**
     * Checks if the number of cols selected is more than 0
     * */
    public areRowsSelected(): boolean {
        return this.params.api.getSelectedRows().length > 0;
    }

}
