import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { GraphqlService } from './graphql.service';
import { LoggerService } from './logger';

export interface UsageNotification {
    id: number,
    emails: Array<string | null>,
    percent90: boolean,
    percent100: boolean
}

export class CustomerData {
    private readonly _id: number;
    private readonly _name: string;
    private readonly _testCustomer: boolean;

    constructor(id: number, name: string, testCustomer: boolean) {
        this._id = id;
        this._name = name;
        this._testCustomer = testCustomer;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get testCustomer(): boolean {
        return this._testCustomer;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private readonly customerData: Promise<CustomerData>;

    constructor(private graphQlService: GraphqlService, private authService: AuthService) {
        this.customerData = new Promise<CustomerData>((resolve) => {
            this.authService.getLoggedInPromise().then(() => {
                this.graphQlService.getCustomerData().then((value) => {
                    const customerData = new CustomerData(value.data.getCustomerData.customerid,
                        value.data.getCustomerData.current_name, value.data.getCustomerData.is_test_customer);
                    LoggerService.debug('Got customer data: ', value.data.getCustomerData);
                    resolve(customerData);
                });
            });
        });
    }

    public getCurrentCustomer(): Promise<CustomerData> {
        return this.customerData;
    }

}
