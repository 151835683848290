import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GraphqlService } from '../../../service/graphql.service';

@Component({
    selector: 'app-send-sms',
    templateUrl: './send-sms.component.html',
    styleUrls: ['./send-sms.component.scss']
})
export class SendSmsComponent implements ICellRendererAngularComp {


    private params: ICellRendererParams | undefined;

    public smsBody = '';
    public sending = false;


    constructor(private graphQlClient: GraphqlService) {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    public close() {
        this.params?.api.stopEditing(true);
    }

    sendSMS() {
        if (this.params?.node.id && !this.sending) {
            this.sending = true;
            this.graphQlClient.sendSMS(this.params?.node.id, this.smsBody).finally(() => {
                this.sending = false
                this.close();
            })
        }
    }


    // please leave this here - otherwise focus lost will produce weird behaviour!
    public getValue() {
        return this.params?.value
    }
}
