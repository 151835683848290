import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ApplyColumnStateParams,
    CellClickedEvent,
    ColDef,
    ColGroupDef,
    ColumnMovedEvent,
    ColumnResizedEvent,
    FilterChangedEvent,
    FilterModel,
    GetLocaleTextParams,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    SelectionChangedEvent,
    ValueFormatterParams,
    ValueSetterParams
} from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { Sim } from '../../../graphql/graphql.generated';
import { environment } from '../../environments/environment';
import { ByteFormatPipe } from '../pipes/byte-format.pipe';
import { MsisdnFormatPipe } from '../pipes/msisdn-format.pipe';
import { GraphqlService } from '../service/graphql.service';
import { LoggerService } from '../service/logger';
import { ToastService } from '../service/toast.service';
import { UserConfigService } from '../service/user-config.service';
import { CustomFieldComponent } from '../shared/ag-cell-editor/custom-field/custom-field.component';
import { DataVolumeEditComponent } from '../shared/ag-cell-editor/data-volume-edit/data-volume-edit.component';
import { SendSmsComponent } from '../shared/ag-cell-editor/send-sms/send-sms.component';
import { DataVolumeLimitComponent } from '../shared/ag-cell-renderer/data-volume-limit/data-volume-limit.component';
import { InSessionComponent } from '../shared/ag-cell-renderer/in-session/in-session.component';
import { MsisdnComponent } from '../shared/ag-cell-renderer/msisdn/msisdn.component';
import { SimStatusComponent } from '../shared/ag-cell-renderer/sim-status/sim-status.component';
import { SmsAmountComponent } from '../shared/ag-cell-renderer/sms-amount/sms-amount.component';
import { TextContainerWithCopyToClipboardComponent } from '../shared/ag-cell-renderer/text-container-with-copy-to-clipboard/text-container-with-copy-to-clipboard.component';
import { StatusFilterComponent } from '../shared/ag-filter/status-filter/status-filter.component';
import { CheckboxClearSelectorComponent } from '../shared/ag-headers/checkbox-clear-selector/checkbox-clear-selector.component';
import { SimDatasource } from './sim-data-source';
import { SimDetailComponent } from './sim-detail/sim-detail.component';


@Component({
    selector: 'app-main',
    templateUrl: './sim-list.component.html',
    styleUrls: ['./sim-list.component.scss']
})
export class SimListComponent implements OnInit, OnDestroy {

    @HostBinding('class')
    private readonly classes = 'container-fluid d-flex flex-column flex-fill';

    @ViewChild(SimDetailComponent)
    private simDetailComponent!: SimDetailComponent;

    // AG Grid definitions
    protected readonly datasourceForTable: SimDatasource;

    protected selectedRows?: Sim[] = undefined;

    private updatedSimSubscription?: Subscription;
    private resetClientsSubscription?: Subscription;
    protected gridApi?: GridApi;

    public constructor(private graphQlClient: GraphqlService,
                       private i18nextPipe: I18NextPipe,
                       private byteFormatPipe: ByteFormatPipe,
                       private msisdnFormatPipe: MsisdnFormatPipe,
                       private route: ActivatedRoute,
                       private router: Router,
                       private userConfig: UserConfigService,
                       private toastService: ToastService) {

        this.datasourceForTable = new SimDatasource(this.graphQlClient, this.toastService);
    }

    protected getGridOptions(): GridOptions {
        return {
            onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
            onSelectionChanged: (event: SelectionChangedEvent) => this.onSelectionChanged(event),
            onColumnResized: (event: ColumnResizedEvent) => this.onColumnResized(event),
            onColumnVisible: () => this.onColumnVisibleChanged(),
            onColumnMoved: (event: ColumnMovedEvent) => this.onColumnMoved(event),
            getLocaleText: (params: GetLocaleTextParams) => {
                return this.i18nextPipe.transform(params.key, {
                    ns: 'grid',
                    defaultValue: params.defaultValue
                });
            },
            onFilterChanged: (event: FilterChangedEvent) => this.onFilterChanged(event),
            getRowId: (params: GetRowIdParams) => this.getRowId(params),
            rowClassRules: {
                'bg-primary-subtle': (params) => {
                    return (this.simDetailComponent.currentSim() && params.data && params.data.iccid === this.simDetailComponent.currentSim()?.iccid);
                }
            },
            datasource: this.datasourceForTable,
            columnDefs: this.createColumnDefinitions(),
            defaultColDef: {
                flex: 1,
                resizable: true,
                minWidth: 100,
                sortable: false
            },
            overlayNoRowsTemplate: '<div>No Rows</div>',
            overlayLoadingTemplate: '<div class="spinner-border m-5" role="status">\n' +
                '  <span class="visually-hidden">Loading...</span>\n' +
                '</div>',
            animateRows: false,
            cacheBlockSize: environment.aggrid.cacheBlockSize,
            cacheOverflowSize: environment.aggrid.cacheOverflowSize,
            infiniteInitialRowCount: environment.aggrid.infiniteInitialRowCount,
            rowBuffer: environment.aggrid.rowBuffer,
            rowModelType: 'infinite',
            rowSelection: 'multiple',
            maxConcurrentDatasourceRequests: 1,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            cellFlashDuration: 2000,
            suppressRowTransform: true,
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: true,
            stopEditingWhenCellsLoseFocus: true
        };
    }

    private createColumnDefinitions(): (ColDef | ColGroupDef)[] {
        const colDef: ColDef[] = [
            {
                showDisabledCheckboxes: true,
                checkboxSelection: true,
                lockPosition: true,
                width: 30,
                maxWidth: 30,
                resizable: false,
                pinned: true,
                headerComponent: CheckboxClearSelectorComponent,
                suppressMovable: true,
                lockVisible: true
            },
            {
                field: 'iccid',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'in'],
                    maxNumConditions: 1,
                    buttons: ['apply', 'clear']
                },
                sortable: true,
                minWidth: 220,
                cellRenderer: TextContainerWithCopyToClipboardComponent,
                lockPosition: true,
                pinned: true,
                suppressMovable: true,
                lockVisible: true,
                onCellClicked: (event) => this.openDetails(event),
                cellClass: 'text-decoration-underline'
            },
            {
                field: 'custom_field_1',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'in'],
                    maxNumConditions: 1,
                    buttons: ['apply', 'clear']
                },
                editable: true,
                cellRenderer: TextContainerWithCopyToClipboardComponent,
                cellEditor: CustomFieldComponent,
                minWidth: 250,
                enableCellChangeFlash: true,
                sortable: true,
                valueSetter: (params: ValueSetterParams) => {
                    const rowNode = params.api.getRowNode(params.data.iccid);
                    const dataCopy = JSON.parse(JSON.stringify(params.data));
                    dataCopy.custom_field_1 = params.newValue;
                    if (rowNode) {
                        rowNode.updateData(dataCopy);
                    }
                    return true;
                },
                onCellClicked: (event) => this.openDetails(event),
                cellClass: 'text-decoration-underline'
            },
            {
                field: 'statusid',
                sortable: true,
                filter: StatusFilterComponent,
                cellRenderer: SimStatusComponent,
                enableCellChangeFlash: true,
                minWidth: 133
            },
            {
                field: 'in_session_since',
                sortable: true,
                cellRenderer: InSessionComponent,
                maxWidth: 100,
                enableCellChangeFlash: true
            },
            {
                field: 'previous_month_data_volume',
                sortable: true,
                type: 'rightAligned',
                minWidth: 140,
                maxWidth: 250,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) {
                        const formatted = this.byteFormatPipe.transform(Number(params.value));
                        return (formatted) ? String(formatted) : '';
                    } else {
                        return '';
                    }
                },
                // For breaking the header dynamic to the header size
                wrapHeaderText: true
            },
            {
                field: 'monthly_data_volume',
                sortable: true,
                type: 'rightAligned',
                maxWidth: 150,
                enableCellChangeFlash: true,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) {
                        const formatted = this.byteFormatPipe.transform(Number(params.value));
                        return (formatted) ? String(formatted) : '';
                    } else {
                        return '';
                    }
                }
            },
            {
                field: 'monthly_data_limit',
                sortable: true,
                cellRenderer: DataVolumeLimitComponent,
                cellEditor: DataVolumeEditComponent,
                type: 'rightAligned',
                minWidth: 150,
                maxWidth: 200,
                editable: true,
                enableCellChangeFlash: true,
                // we need a value setter for a cell editor as when the value changes the grid tries to update
                // the immutable table data. so we need to create a copy of that and then re-set it
                valueSetter: (params: ValueSetterParams) => {
                    const rowNode = params.api.getRowNode(params.data.iccid);
                    const dataCopy = JSON.parse(JSON.stringify(params.data));
                    dataCopy.monthly_data_limit = params.newValue;
                    if (rowNode) {
                        rowNode.updateData(dataCopy);
                    }
                    return true;
                }
            },
            {
                field: 'monthly_sms',
                minWidth: 180,
                maxWidth: 200,
                type: 'rightAligned',
                editable: true,
                cellRenderer: SmsAmountComponent,
                cellEditor: SendSmsComponent,
                cellEditorPopup: true,
                enableCellChangeFlash: true
            },
            {
                field: 'providerid',
                sortable: true,
                minWidth: 100,
                maxWidth: 100,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) {
                        const formatted = this.i18nextPipe.transform('product.prod_' + params.value);
                        return String(formatted);
                    } else {
                        return '';
                    }
                }
            },
            {
                field: 'imei',
                filter: 'agTextColumnFilter',
                minWidth: 180,
                maxWidth: 250,
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'in'],
                    maxNumConditions: 1,
                    buttons: ['apply', 'clear']
                },
                sortable: true,
                enableCellChangeFlash: true,
                cellRenderer: TextContainerWithCopyToClipboardComponent,
                valueFormatter: (params: ValueFormatterParams) => (params?.value || '')
            },
            {
                field: 'imsi',
                filter: 'agTextColumnFilter',
                minWidth: 188,
                maxWidth: 250,
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'in'],
                    maxNumConditions: 1,
                    buttons: ['apply', 'clear']
                },
                sortable: true,
                cellRenderer: TextContainerWithCopyToClipboardComponent,
                valueFormatter: (params: ValueFormatterParams) => (params?.value || ''),
                hide: true
            },
            {
                field: 'msisdn',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'in'],
                    maxNumConditions: 1,
                    buttons: ['apply', 'clear']
                },
                minWidth: 185,
                maxWidth: 230,
                sortable: true,
                cellRenderer: MsisdnComponent,
                valueFormatter: (params: ValueFormatterParams) => (this.msisdnFormatPipe.transform(params.value) || '')
            },
            {
                field: 'activation_timestamp',
                sortable: true,
                cellDataType: 'date',
                minWidth: 155,
                enableCellChangeFlash: true,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) {
                        const formatted = this.i18nextPipe.transform('formats:dateShort', { date: params.value });
                        return String(formatted);
                    } else {
                        return '';
                    }
                }
            }
        ];

        colDef.filter((col) => col.field).forEach((col) => {
            col.headerName = this.i18nextPipe.transform('table.header.' + col.field);
        });

        return colDef;
    }

    private onFilterChanged(event: FilterChangedEvent): void {

        const filterModel: FilterModel = event.api.getFilterModel();
        let filter = null;
        if (filterModel && !(Object.keys(filterModel).length === 0 && filterModel.constructor === Object)) {
            filter = JSON.stringify(filterModel);
        }

        this.router.navigate([], {
            queryParams: { filter },
            queryParamsHandling: 'merge'
        }).then();
    }

    private onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.loadTableConfig(this.gridApi);
        this.datasourceForTable.setGridApi(this.gridApi);
        params.api.showLoadingOverlay();
        params.api.showNoRowsOverlay();
        this.createSubscriptions();

        // Set filter from URL parameter
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['filter']) {
            this.gridApi.setFilterModel(JSON.parse(queryParams['filter']));
        }
    }

    private onSelectionChanged(params: SelectionChangedEvent) {
        LoggerService.debug('onSelectionChanged(): ', params);
        const selectedRows = params.api.getSelectedRows();
        if (selectedRows && selectedRows.length > 1) {
            LoggerService.debug('onSelectionChanged(): multiselect');
            this.selectedRows = selectedRows;
        } else if (selectedRows && selectedRows.length == 1) {
            LoggerService.debug('onSelectionChanged(): single select');
            this.selectedRows = selectedRows;
        } else {
            LoggerService.debug('onSelectionChanged(): no row selected');
            this.selectedRows = undefined;
        }
    }

    private onColumnResized(params: ColumnResizedEvent) {
        if (params.finished) {
            this.storeLocalConfig(this.gridApi!);
        }
    }

    private onColumnMoved(params: ColumnMovedEvent) {
        if (params.finished) {
            this.storeLocalConfig(this.gridApi!);
        }
    }

    private onColumnVisibleChanged() {
        this.storeLocalConfig(this.gridApi!);
    }

    public ngOnInit(): void {
        // we need to get informed about subscription client gets closed!
        this.resetClientsSubscription = this.graphQlClient.subscribeClientsReset().subscribe(() => {
            this.createSubscriptions();
        });
    }

    public ngOnDestroy(): void {
        if (this.updatedSimSubscription) {
            this.updatedSimSubscription.unsubscribe();
        }
        if (this.resetClientsSubscription) {
            this.resetClientsSubscription.unsubscribe();
        }
    }

    private createSubscriptions() {
        if (this.updatedSimSubscription) {
            this.updatedSimSubscription.unsubscribe();
        }
        this.updatedSimSubscription = this.graphQlClient.subscribeUpdatedSim().subscribe((data: Sim) => {
            const rowNode = this.gridApi?.getRowNode(data.iccid);
            if (rowNode) {
                rowNode.updateData(data);
            }
            if (this.selectedRows?.find((element) => element.iccid === data.iccid)) {
                this.selectedRows = this.gridApi?.getSelectedRows();
            }
        });
        this.graphQlClient.subscribeToUpdatedSim();
    }

    private getRowId(param: GetRowIdParams) {
        return param.data.iccid;
    }

    private storeLocalConfig(gridApi: GridApi) {
        const columnState = gridApi.getColumnState();
        this.userConfig.getUserConfig().then((configMap) => {
            configMap.set(UserConfigService.KEY_COLUMN_STATE_SIM_LIST, JSON.stringify(columnState));
        });
    }

    private loadTableConfig(gridApi: GridApi) {
        this.userConfig.getUserConfig().then((configMap) => {
            const columnModel = configMap.get(UserConfigService.KEY_COLUMN_STATE_SIM_LIST);
            if (columnModel) {
                const stateParams: ApplyColumnStateParams = {
                    applyOrder: true,
                    defaultState: { sort: null },
                    state: JSON.parse(columnModel)
                };
                gridApi.applyColumnState(stateParams);
            }
        });
    }

    protected quickSearchEvent($event: string) {
        this.datasourceForTable.setQuickFilter($event);
        this.gridApi?.setGridOption('datasource', this.datasourceForTable);
    }

    private openDetails(event: CellClickedEvent) {
        let open = true;
        // check if the event's cell is in edit mode. If so, do not
        // open the detail component! This fixes the problem with editing
        // column and opening the detail view for device name!
        event.api?.getEditingCells().forEach((cellPosition) => {
            if (cellPosition.column.getColId() === event.column.getColId() && cellPosition.rowIndex === event.rowIndex) {
                open = false;
                return;
            }
        });
        if (open) {
            this.simDetailComponent.open(event.data);
        }

    }

    protected onDetailsSimChanged($event: { old: Sim | undefined, new: Sim | undefined }) {
        const redrawNodes: IRowNode<Sim>[] = [];
        [$event.old, $event.new].forEach((e: Sim | undefined) => {
            if (!e) return;
            const rowNode = this.gridApi?.getRowNode(e.iccid);
            if (rowNode) redrawNodes.push(rowNode);
        });
        this.gridApi?.redrawRows({ rowNodes: redrawNodes });
    }
}
