import { Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ColDef, ColGroupDef, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastService } from '../../../service/toast.service';
import { SimEventDatasource } from './sim-event-data-source';

@Component({
    selector: 'app-sim-detail-event-table',
    templateUrl: './sim-detail-event-table.component.html',
    styleUrl: './sim-detail-event-table.component.scss'
})
export class SimDetailEventTableComponent implements OnChanges, OnDestroy {

    @Input()
    public iccid?: string;

    private i18nextPipe = inject(I18NextPipe);
    private graphql = inject(GraphqlService);
    private toastService = inject(ToastService);

    protected gridApi?: GridApi;
    private readonly datasourceForTable: SimEventDatasource;

    private updatedSimEvent$?: Subscription;

    public constructor() {
        this.datasourceForTable = new SimEventDatasource(this.graphql, this.toastService);
    }

    public ngOnDestroy(): void {
        if (this.updatedSimEvent$) this.updatedSimEvent$.unsubscribe();
        this.graphql.unsubscribeUpdateSimEvent();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['iccid']) {
            this.onIccidChange();
        }
    }

    private onIccidChange() {
        if (!this.iccid) return;
        this.datasourceForTable.setIccid(this.iccid);
        this.gridApi?.setGridOption('datasource', this.datasourceForTable);

        this.graphql.unsubscribeUpdateSimEvent();
        this.graphql.subscribeToUpdateSimEvent(this.iccid);
        if (this.updatedSimEvent$) this.updatedSimEvent$.unsubscribe();
        this.updatedSimEvent$ = this.graphql.subscribeUpdatedSimEvent().subscribe(() => {
            this.gridApi?.purgeInfiniteCache();
            this.gridApi?.refreshInfiniteCache();
        });
    }

    protected getGridOptions(): GridOptions {
        if (!this.iccid) return {};
        return {
            datasource: this.datasourceForTable,
            onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
            getRowId: (params: GetRowIdParams) => this.getRowId(params),
            rowHeight: 50,
            columnDefs: this.createColumnDefinitions(),
            defaultColDef: {
                flex: 1,
                resizable: true,
                minWidth: 100,
                sortable: false
            },
            overlayNoRowsTemplate: '<div>No Rows</div>',
            overlayLoadingTemplate: '<div class="spinner-border m-5" role="status">\n' +
                '  <span class="visually-hidden">Loading...</span>\n' +
                '</div>',
            animateRows: false,
            cacheBlockSize: environment.aggrid.cacheBlockSize,
            cacheOverflowSize: environment.aggrid.cacheOverflowSize,
            infiniteInitialRowCount: environment.aggrid.infiniteInitialRowCount,
            rowBuffer: environment.aggrid.rowBuffer,
            rowModelType: 'infinite',
            rowSelection: 'single',
            maxConcurrentDatasourceRequests: 1,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            suppressRowTransform: true,
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: true,
            stopEditingWhenCellsLoseFocus: true
        };
    }

    private createColumnDefinitions(): (ColDef | ColGroupDef)[] {
        const colDef: ColDef[] = [
            {
                field: 'timestamp',
                initialWidth: 175,
                minWidth: 100,
                maxWidth: 200,
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) {
                        const formatted = this.i18nextPipe.transform('formats:dateTimeMedium', { date: params.value * 1000 });
                        return String(formatted);
                    } else {
                        return '';
                    }
                },
                cellStyle: {
                    'display': 'flex'
                }
            },
            {
                field: 'details',
                wrapText: true,
                cellStyle: {
                    'display': 'flex',
                    'line-height': '20px'
                }
            }
        ];

        colDef.filter((col) => col.field).forEach((col) => {
            col.headerName = this.i18nextPipe.transform('table.header.' + col.field, { defaultValue: col.field });
        });

        return colDef;
    }

    private onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.datasourceForTable.setGridApi(this.gridApi);
        params.api.showLoadingOverlay();
        params.api.showNoRowsOverlay();
    }

    private getRowId(params: GetRowIdParams) {
        return params.data.id;
    }

}
