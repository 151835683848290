import { FilterDescriptor } from 'devextreme/data';

export class DevxFilterConverter {

    private readonly _hasTimestampConfigured: boolean = false;
    private readonly _startTimestamp: number = 0;
    private readonly _stopTimestamp: number = 0;

    private readonly _hasEventTypesConfigured: boolean = false;
    private readonly _eventTypes: string[] | undefined = undefined;

    public constructor(devxFilter: FilterDescriptor | Array<FilterDescriptor>) {
        if (devxFilter) {
            this._hasTimestampConfigured = devxFilter.indexOf('startTimestamp') > -1;
            if (this._hasTimestampConfigured) {
                this._startTimestamp = devxFilter[devxFilter.indexOf('startTimestamp') + 1];
                this._stopTimestamp = devxFilter[devxFilter.indexOf('stopTimestamp') + 1];
            }
            this._hasEventTypesConfigured = devxFilter.indexOf('eventTypes') > -1;
            if (this._hasEventTypesConfigured) {
                this._eventTypes = devxFilter[devxFilter.indexOf('eventTypes') + 1];
            }
        }
    }


    get hasTimestampConfigured(): boolean {
        return this._hasTimestampConfigured;
    }

    get startTimestamp(): number {
        return this._startTimestamp;
    }

    get stopTimestamp(): number {
        return this._stopTimestamp;
    }

    get hasEventTypesConfigured(): boolean {
        return this._hasEventTypesConfigured;
    }

    get eventTypes(): string[] | undefined {
        return this._eventTypes;
    }
}
