import { v4 as uuid } from 'uuid';

export class ErrorMessage {

    private _id: string | undefined;

    constructor(public type: string | undefined, public message: string | undefined) {
        this._id = uuid();
    }


    get id(): string | undefined {
        return this._id;
    }
}
