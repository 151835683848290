import { Component, inject, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { I18NextPipe } from 'angular-i18next';
import { GraphqlService } from '../../../service/graphql.service';
import { ToastMessageBuilder, ToastService } from '../../../service/toast.service';

@Component({
    selector: 'app-custom-field',
    templateUrl: './custom-field.component.html',
    styleUrl: './custom-field.component.scss'
})
export class CustomFieldComponent implements ICellEditorAngularComp, OnInit {

    protected textField: string | undefined;
    protected params: ICellEditorParams | undefined;
    protected saving: boolean = false;
    private iccid: string | undefined;

    private graphQlService = inject(GraphqlService);
    private toastService = inject(ToastService);
    private i18next = inject(I18NextPipe);

    constructor() {
    }

    ngOnInit(): void {
        this.textField = this.params?.value; // Set initial value
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.iccid = params.node.id;
    }

    getValue(): string | undefined {
        return this.textField;
    }

    // Optional methods for lifecycle management
    refresh(params: ICellEditorParams): boolean {
        if (this.textField !== params.value) {
            this.textField = params.value;
        }
        return true; // Indicate no refresh needed
    }

    onKeyDown($event: KeyboardEvent) {
        if ($event.key === 'Escape') {
            this.close();
        } else if ($event.key === 'Enter') {
            this.save();
        } else if ($event.code.startsWith('Arrow')) {
            // Allow cursor movement
            $event.stopPropagation();
        } else if ($event.metaKey && $event.code === 'KeyA') {
            // Select All
            $event.stopPropagation();
        }
    }

    public close() {
        this.params?.api.stopEditing(true);
    }

    protected save(): void {
        this.saving = true;
        if (this.iccid) {
            const newFieldValue: string = this.textField || '';
            this.graphQlService.updateSim(this.iccid, undefined, undefined, newFieldValue).then(() => {
                this.textField = newFieldValue;
            }).catch((error) => {
                const text: string = this.i18next.transform('settings.notifications.graphql-operations.error', { error: error });
                this.toastService.show(ToastMessageBuilder.error().text(text).build());
            }).finally(() => {
                this.saving = false;
                this.close();
            });
        }
    }
}
